import type { SessionResult } from '@mentimeter/http-clients';
import * as React from 'react';

export interface QuestionResults extends SessionResult {
  isPlaceholderResults: boolean;
}

const questionResults = React.createContext<QuestionResults | undefined>(
  undefined,
);
questionResults.displayName = 'QuestionResultsContext';
export const QuestionResultsProvider = questionResults.Provider;
export const useQuestionResults = () => React.useContext(questionResults);
