import React from 'react';
import type {
  QuestionChoice,
  QuestionRange,
  Question,
  SegmentT,
  ModuleId,
} from '@mentimeter/http-clients';
import { importPreviewResults } from '@mentimeter/question-modules-importer';
import type { QuestionModuleResult } from '@mentimeter/question-modules-types';
import { usePreviewResultsIterator } from './usePreviewResultsIterator';

interface useQuestionModulesPreviewResultsProps {
  moduleId: ModuleId;
  previewResultsEnabled: boolean;
  choices: QuestionChoice[];
  range: QuestionRange | undefined;
  correct_area: Question['correct_area'] | undefined;
  segments: SegmentT[] | undefined;
}

export const useQuestionModulesPreviewResults = ({
  moduleId,
  previewResultsEnabled,
  choices,
  range,
  segments,
  correct_area,
}: useQuestionModulesPreviewResultsProps) => {
  const { getPreviewResultsIteration, timeToNextIteration } =
    importPreviewResults(moduleId);

  const getNextResults = React.useCallback(
    (iteration: number, previous: QuestionModuleResult | undefined) => {
      if (!getPreviewResultsIteration) {
        return undefined;
      }
      const defaultRange: QuestionRange = { min: 0, max: 10 };
      switch (moduleId) {
        case 'choices':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: {
              type: 'choices',
              choices,
              segments,
            },
          });
        case 'open':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: null,
          });
        case 'rating':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: {
              type: 'rating',
              choices,
              range: range ?? defaultRange,
            },
          });
        case 'ranking':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: {
              type: 'ranking',
              choices,
            },
          });
        case 'scales':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: {
              type: 'scales',
              choices,
              range: range ?? defaultRange,
            },
          });
        case '100-points':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: {
              type: 'prioritisation',
              choices,
            },
          });
        case 'wordcloud':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: null,
          });
        case 'pin-on-image':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: {
              type: 'pin_on_image',
              correctArea: correct_area ?? null,
            },
          });
        case 'numerical-question':
          return getPreviewResultsIteration({
            iteration,
            previous,
            extras: {
              type: 'scales',
              choices,
              range: range ?? defaultRange,
            },
          });
        default:
          return undefined;
      }
    },
    [
      getPreviewResultsIteration,
      choices,
      correct_area,
      range,
      segments,
      moduleId,
    ],
  );

  return usePreviewResultsIterator<QuestionModuleResult>(
    previewResultsEnabled,
    getNextResults,
    timeToNextIteration,
  );
};
