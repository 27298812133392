import * as React from 'react';
import type { ThemeProps } from '@mentimeter/ragnar-react';
import { withTheme } from '@mentimeter/ragnar-react';
import type { Reaction } from '@mentimeter/http-clients';
import {
  type ReactionQueueItem,
  useRealtimeReactionsStore,
} from '@mentimeter/reactions-realtime';
import { useThemeComponent } from '../../../misc/themes/theme-components';
import variants from './variants';

const REACTION_OFFSET = 500;
const REACTION_SCALE_FACTOR = 1.25;
const REACTION_ICON_SIZE = 30;

interface Props extends ThemeProps {
  reactionId: Reaction;
  queue: ReactionQueueItem[];
}

const C = React.memo<Props>(({ reactionId, queue, theme }) => {
  const { ReactionMap } = useThemeComponent();
  const Icon = ReactionMap[reactionId];

  if (!Icon) return null;

  return (
    <>
      {queue.map((item) => {
        if (!item) return null;
        const color =
          theme.visualizationColors.fillColors[item.colorIndex] ??
          theme.visualizationColors.fillColors[0]!;
        const ItemComponent = variants[item.index % variants.length]!;

        return (
          <ItemComponent
            key={item.id}
            style={{ opacity: 0, position: 'absolute' }}
            duration={item.duration}
            delay={item.delay}
          >
            <Icon
              size={REACTION_ICON_SIZE * REACTION_SCALE_FACTOR}
              color={color}
            />
          </ItemComponent>
        );
      })}
    </>
  );
});

interface AnimationT {
  reactionId: Reaction;
  reactionQueue: ReactionQueueItem[];
}

const AnimationComponent = ({
  reactionId,
  reactionQueue,
  theme,
}: AnimationT & ThemeProps) => {
  const timers = React.useRef<Record<string, NodeJS.Timeout>>({});
  const popFromQueue = useRealtimeReactionsStore((state) => state.popFromQueue);

  // Clean-up timers that did not finish when unmounting.
  React.useEffect(() => {
    return () => {
      // We need to know which timers that have not been triggered yet. Eslint complains, but have no other solution for now...
      // eslint-disable-next-line
      const timerData = { ...timers.current };
      const timerIds = Object.keys(timerData);
      timerIds.forEach((id) => {
        popFromQueue(reactionId, id);
        clearTimeout(timerData[id]);
      });
    };
  }, [popFromQueue, reactionId]);

  React.useEffect(() => {
    reactionQueue.forEach((item) => {
      if (timers.current[item.id]) return;

      timers.current[item.id] = setTimeout(() => {
        popFromQueue(reactionId, item.id);
        if (timers.current) {
          delete timers.current[item.id];
        }
      }, item.duration + REACTION_OFFSET);
    });
  }, [reactionQueue, reactionId, popFromQueue]);

  return <C reactionId={reactionId} queue={reactionQueue} theme={theme} />;
};

const Animation = React.memo(withTheme(AnimationComponent));

export default Animation;
