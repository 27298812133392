import type { PropsWithChildren } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import type { FlexDirectionProperty } from '@mentimeter/ragnar-styled';
import { alpha } from '@mentimeter/ragnar-colors';
import { useOptionalThemeComponent } from '../misc/themes/theme-components';
import { useTheme } from '../data-mediation';
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../utils/constants';

interface PresentationCanvasT {
  width?: number | string;
  height?: number | string;
  flexDirection?: FlexDirectionProperty | undefined;
  disableClickEvents?: boolean | undefined;
}

const C = ({
  backgroundUrl,
  width,
  height,
  flexDirection,
  disableClickEvents,
  children,
}: PropsWithChildren<
  PresentationCanvasT & { backgroundUrl: string | undefined }
>) => {
  return (
    <Box
      as="main"
      aria-label="Presentation view"
      width={width}
      height={height}
      overflow="hidden"
      alignItems="center"
      flexDirection={flexDirection}
      bg="bg"
      extend={({ theme }) => ({
        transition: `background ${theme.durations[2]}s ease`,
        backgroundImage: backgroundUrl
          ? `linear-gradient(
              ${alpha(theme.colors.bg, theme.backdropAlpha ?? 1)},
              ${alpha(theme.colors.bg, theme.backdropAlpha ?? 1)}
              ), url(${backgroundUrl})`
          : undefined,
        backgroundSize: 'cover',
        pointerEvents: disableClickEvents ? 'none' : 'auto',
      })}
      data-testid="presentation-canvas"
    >
      {children}
    </Box>
  );
};

export const PresentationCanvas = ({
  height = CANVAS_HEIGHT,
  width = CANVAS_WIDTH,
  flexDirection,
  disableClickEvents = false,
  renderBackground = true,
  children,
  slideImage,
}: PropsWithChildren<
  PresentationCanvasT & {
    renderBackground?: boolean;
    slideImage: string | undefined;
  }
>) => {
  const { background_cropped_image_url: presentationThemeImage } = useTheme();
  const { Background: LegacyMovingBackground } = useOptionalThemeComponent();

  return (
    <C
      width={width}
      height={height}
      backgroundUrl={slideImage || presentationThemeImage}
      flexDirection={flexDirection}
      disableClickEvents={disableClickEvents}
    >
      {LegacyMovingBackground !== undefined &&
        !slideImage &&
        renderBackground && (
          <>
            <LegacyMovingBackground />
            <LegacyMovingBackgroundColorOverlay />
          </>
        )}
      {children}
    </C>
  );
};

const LegacyMovingBackgroundColorOverlay = () => (
  <Box
    bg="bg"
    position="absolute"
    width="100%"
    height="100%"
    top={0}
    left={0}
    zIndex={0}
    extend={({ theme }) => {
      return {
        transition: `all ${theme.durations[2]}s ease`,
        opacity: theme.backdropAlpha,
      };
    }}
  />
);
