import * as React from 'react';
import type {
  EmptyT,
  QuestionModuleResult,
} from '@mentimeter/question-modules-types';

export const defaultState: QuestionModuleResult = {
  timestamp: 0,
  respondents: 0,
  type: 'choices',
  results: [],
  segments: [],
  isPlaceholderResults: false,
};

const sfinxResults = React.createContext<QuestionModuleResult | EmptyT>(
  defaultState,
);
sfinxResults.displayName = 'SfinxResultsContext';
export const SfinxResultsProvider = sfinxResults.Provider;
export const useSfinxResults = () => React.useContext(sfinxResults);

export const useQuestionModuleResults = <
  T extends QuestionModuleResult | EmptyT,
>() => {
  const result = React.useContext(sfinxResults);
  return result as T;
};
