/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import { usePresentation } from '@mentimeter/presentation-state';
import { useQuestion } from '../../../data-mediation/question';

export type ShowMediaContext = [
  showMedia: boolean,
  setShowMedia: (showMedia: boolean) => void,
];

const defaultState: ShowMediaContext = [false, () => {}];
const context = React.createContext<ShowMediaContext>(defaultState);
context.displayName = 'ShowMediaContext';

const Provider = context.Provider;

export const ShowMediaProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { public_key } = useQuestion();
  const showMedia = usePresentation(
    (state) => state.state?.slideStates?.[public_key]?.showMedia || false,
  );
  const setSlideState = usePresentation((state) => state.actions.setSlideState);

  const setShowMedia = React.useCallback(
    (showMedia: boolean) => {
      setSlideState(public_key, { showMedia });
    },
    [public_key, setSlideState],
  );

  return <Provider value={[showMedia, setShowMedia]}>{children}</Provider>;
};

export const useShowMedia = () => React.useContext(context);
