import type { QuizPlayerScore } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import React from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR, { useSWRConfig } from 'swr';
import { quizPlayersScoreCacheKey } from './cache-keys';
import type { WrappedMutator } from './types';

export type QuizPlayersScoreData = ReturnType<typeof useQuizPlayersScore>;

export const useQuizPlayersScore = (
  {
    questionId,
    shouldFetch = true,
    clearCache = true,
  }: {
    questionId: string | undefined;
    shouldFetch?: boolean;
    clearCache?: boolean;
  },
  config?: SWRConfiguration<QuizPlayerScore[] | undefined>,
) => {
  const cacheKey =
    shouldFetch && questionId
      ? quizPlayersScoreCacheKey(questionId)
      : undefined;
  const { mutate, ...rest } = useSWR<QuizPlayerScore[] | undefined>(
    cacheKey,
    async () => {
      if (!questionId) return undefined;
      const { data } = await core().questions.quizPlayers.get(
        questionId,
        clearCache,
      );
      return data;
    },
    config,
  );

  const fetchResults = React.useCallback(async () => {
    if (!questionId) return undefined;
    const { data } = await core().questions.quizPlayers.get(questionId, true);
    mutate(data, { revalidate: false });
    return data;
  }, [mutate, questionId]);

  return { mutate, fetchResults, ...rest };
};

export const useQuizPlayersScoreMutate = (questionId: string | undefined) => {
  const { mutate } = useSWRConfig();
  return React.useCallback<WrappedMutator<QuizPlayerScore[]>>(
    (data, options) => {
      mutate(
        questionId ? quizPlayersScoreCacheKey(questionId) : undefined,
        data,
        {
          revalidate: false,
          ...options,
        },
      );
    },
    [questionId, mutate],
  );
};
