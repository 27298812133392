/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { ThemeProvider } from '@mentimeter/ragnar-react';
// Font sizes is created in an increasingly scale, where three
// any immediate sizes works well together

const factor = 1;
const visualizationFontSizes = [
  12 * factor,
  16 * factor,
  20 * factor,
  24 * factor,
  28 * factor,
  32 * factor,
  36 * factor,
  40 * factor,
  44 * factor,
  48 * factor,
  64 * factor,
  72 * factor,
  80 * factor,
  88 * factor,
  96 * factor,
  104 * factor,
];
export const FONT_SIZE_BASE_INDEX = 10;

// Overrides only the ones we need
export const DSC = {
  heights: [60, 90],
  fontSizes: visualizationFontSizes,
  fontWeights: designSystemConfig.fontWeights,
};

export const UIDSC = {
  heights: designSystemConfig.heights,
  fontSizes: designSystemConfig.fontSizes,
};

interface Props {
  children: React.ReactNode;
}
export const InternalFontScalingProvider = (props: Props) => (
  <ThemeProvider {...props} theme={DSC} />
);
