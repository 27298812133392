export type BreakpointsT = Array<{
  count?: number;
  lineCount?: number;
  offset: number;
}>;

// This is a general breakpoint structure for potentially long texts
const DEFAULT_BREAKPOINTS: BreakpointsT = [
  { count: 9000, lineCount: 35, offset: -8 },
  { count: 6000, lineCount: 25, offset: -7 },
  { count: 3000, lineCount: 15, offset: -6 },
  { count: 2000, lineCount: 12, offset: -5 },
  { count: 1500, lineCount: 8, offset: -4 },
  { count: 960, lineCount: 6, offset: -3 },
  { count: 700, lineCount: 5, offset: -2 },
  { count: 400, lineCount: 4, offset: -1 },
];

interface FontOffsetT {
  height: number;
  width: number;
  breakpoints?: BreakpointsT;
  defaultOffset?: number;
  debug?: boolean;
}

// Arbitrary number take

const fontOffset = ({
  width,
  height,
  breakpoints = DEFAULT_BREAKPOINTS,
  defaultOffset = 0,
}: FontOffsetT): number => {
  // Could be thought of an area or simply the length of the string
  const approximatedCount = height * width;

  for (const breakpoint of breakpoints) {
    const { count, lineCount, offset } = breakpoint;

    if (lineCount !== undefined && count !== undefined) {
      if (approximatedCount >= count || height >= lineCount)
        return offset + defaultOffset;
    }

    if (lineCount !== undefined) {
      if (height >= lineCount) return offset + defaultOffset;
    }

    if (count !== undefined) {
      if (approximatedCount >= count) return offset + defaultOffset;
    }
  }

  return defaultOffset;
};

export default fontOffset;
