import * as React from 'react';
import type { IStyle } from 'fela';
import type { ContinuousItemT } from '../components/Continuous';
import Continuous, { create } from '../components/Continuous';
import { FILL, createStaticUrl } from '../utils';

const wave = createStaticUrl('static/themes/waves/wave-dark.svg');

const wave1: Record<string, IStyle> = {
  '0%': { transform: `translateX(0%)` },
  '50%': { transform: `translateX(-50%)` },
  '100%': { transform: `translateX(0%)` },
};

const wave2: Record<string, IStyle> = {
  '0%': { transform: `translateX(0%) scaleX(-1)` },
  '50%': { transform: `translateX(-50%) scaleX(-1)` },
  '100%': { transform: `translateX(0%) scaleX(-1)` },
};

const map: Array<ContinuousItemT> = [
  {
    id: 'wave-1',
    component: create(
      {
        ...FILL,
        backgroundImage: `url(${wave})`,
        backgroundSize: 'cover',
        width: '200%',
        top: '80%',
        opacity: 0.2,
      },
      wave1,
    ),
    props: { delay: -5, duration: 120000 },
  },
  {
    id: 'wave-2',
    component: create(
      {
        ...FILL,
        backgroundImage: `url(${wave})`,
        backgroundSize: 'cover',
        width: '200%',
        top: '20%',
        opacity: 0.2,
      },
      wave2,
    ),
    props: { delay: 0, duration: 80000 },
  },
];

const Waves = () => {
  return <Continuous components={map} />;
};

export default Waves;
