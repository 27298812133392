import * as React from 'react';
import type { ComponentsT } from '@mentimeter/string-parser';
import { MathSvg, StringParser as SP } from '@mentimeter/string-parser';
import { contrast } from '@mentimeter/ragnar-colors';
import { Link as L, Text, type TextT } from '@mentimeter/ragnar-ui';
import { useTheme } from '../../data-mediation';

const Bold = (props: TextT) => <Text {...defaultPropsBold} {...props} />;

export const defaultPropsBold: TextT = {
  fontWeight: 'semiBold',
  lineHeight: 'inherit',
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
};

const Italic = (props: TextT) => (
  <Text
    fontStyle="italic"
    color="inherit"
    lineHeight="inherit"
    fontSize="inherit"
    fontFamily="inherit"
    {...props}
  />
);

const Code = (props: TextT) => (
  <Text
    fontFamily="monospace"
    lineHeight="inherit"
    color="inherit"
    fontSize="inherit"
    {...props}
  />
);

const Link = ({ href, ...props }: TextT & { href: string }) => {
  const theme = useTheme();

  const BAD_CONTRAST_THRESHOLD = 2;
  const color = React.useMemo(() => {
    if (!theme.bar_color[0]) return theme.text_color;
    return contrast(theme.bar_color[0], theme.background_color) <
      BAD_CONTRAST_THRESHOLD
      ? theme.text_color
      : theme.bar_color[0];
  }, [theme]);

  return (
    <L
      target="_blank"
      rel="noreferrer noopener nofollow"
      href={href}
      color={color}
    >
      <Text lineHeight="inherit" fontSize="inherit" {...props} color="none" />
    </L>
  );
};

const Math = ({ value }: { value: string }) => {
  return <MathSvg math={value} />;
};

export const stringParserComponents: ComponentsT = {
  Strong: Bold,
  Em: Italic,
  Code,
  Math,
  Link,
};

export const StringParser = ({
  source,
  disableLineBreaks,
  allowBlankLines,
  allowHyperlinks,
}: {
  source: string;
  disableLineBreaks: boolean;
  allowBlankLines?: boolean;
  allowHyperlinks?: boolean;
}) => (
  <SP
    components={stringParserComponents}
    source={source}
    disableLineBreaks={disableLineBreaks}
    allowBlankLines={Boolean(allowBlankLines)}
    allowHyperlinks={Boolean(allowHyperlinks)}
  />
);
