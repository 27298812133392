import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT<React.ComponentProps<typeof Background>> = {
  id: 'spark44',
  title: 'Spark44',
  background: Background,
  preview: {
    idle: '#',
    hover: '#',
  },
};

export default Export;
