import * as React from 'react';
import { FILL, createStaticUrl } from '../utils';

const noiseUrl = createStaticUrl(`static/themes/colorjoy/colorjoy-noise.png`);

interface ColorJoyT {
  index: number;
}

const SWATCH = ['#98CAB4', '#7EA9C4', '#9277C2', '#BD7891', '#6A6DAD'];

const ColorJoy = ({ index }: ColorJoyT) => {
  const color = SWATCH[index % SWATCH.length];
  return (
    <div
      style={
        {
          ...FILL,
          background: `${color} url(${noiseUrl})`,
          transition: 'background 1s ease',
        } as React.CSSProperties
      }
    />
  );
};

export default ColorJoy;
