import * as React from 'react';
import KenBurns from '../components/KenBurns';

const IMAGES = [
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-3.jpg',
    backgroundPosition: 'left',
    transformOrigin: '50% 20%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-2.jpg',
    backgroundPosition: 'top',
    transformOrigin: '50% 100%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-1.jpg',
    backgroundPosition: 'right',
    transformOrigin: '70% 70%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-4.jpg',
    backgroundPosition: 'bottom',
    transformOrigin: '50% 50%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-5.jpg',
    backgroundPosition: 'left',
    transformOrigin: '50% 20%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-6.jpg',
    backgroundPosition: 'top',
    transformOrigin: '50% 100%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-7.jpg',
    backgroundPosition: 'right',
    transformOrigin: '70% 70%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-8.jpg',
    backgroundPosition: 'bottom',
    transformOrigin: '50% 50%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-9.jpg',
    backgroundPosition: 'left',
    transformOrigin: '50% 20%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-10.jpg',
    backgroundPosition: 'top',
    transformOrigin: '50% 100%',
  },
  {
    src: 'https://static.mentimeter.com/static/themes/greenhouse/img/greenhouse-11.jpg',
    backgroundPosition: 'right',
    transformOrigin: '70% 70%',
  },
];

const Greenhouse = ({
  currentIndex,
  progress,
}: {
  progress: number;
  currentIndex: number;
}) => {
  return (
    <KenBurns images={IMAGES} currentIndex={currentIndex} progress={progress} />
  );
};

export default Greenhouse;
