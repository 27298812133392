import { createStaticUrl } from '../utils';
import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT<React.ComponentProps<typeof Background>> = {
  id: 'space',
  title: 'Space',
  background: Background,
  preview: {
    idle: createStaticUrl('static/themes/space/preview_idle.jpg'),
    hover: createStaticUrl('static/themes/space/preview_hover.gif'),
  },
};

export default Export;
