import * as React from 'react';

export interface SfinxClusteringState {
  enabled: boolean;
  onActivate: () => Promise<void>;
  hasNoClusters: boolean;
}

const context = React.createContext<SfinxClusteringState | undefined>(
  undefined,
);

export const ClusteringProvider = context.Provider;
context.displayName = 'ClusteringContext';

export const useClustering = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('SfinxError: no clustering data found');
  }
  return data;
};
