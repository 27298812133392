import * as React from 'react';
import { useInterval } from '@mentimeter/react-hooks';

export const usePreviewReactionsIterator = <T>(
  showTestVotes: boolean,
  initialState: T,
  getNextResults: (iteration: number, previous: T) => T,
) => {
  const [testResults, setTestResults] = React.useState({
    iteration: 0,
    results: initialState,
  });

  const setNextTestResults = React.useCallback(
    () =>
      setTestResults((state) => ({
        iteration: state.iteration + 1,
        results: getNextResults(state.iteration + 1, state.results),
      })),
    [getNextResults],
  );

  React.useEffect(() => {
    setTestResults({
      iteration: 0,
      results: initialState,
    });
  }, [initialState]);

  React.useEffect(() => {
    if (!showTestVotes) return;
    setNextTestResults();
  }, [setNextTestResults, showTestVotes]);

  useInterval(setNextTestResults, showTestVotes ? 2000 : null);

  return showTestVotes ? testResults.results : initialState;
};
