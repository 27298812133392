import { createStaticUrl } from '../utils';
import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT<React.ComponentProps<typeof Background>> = {
  id: 'greenhouse',
  title: 'Greenhouse',
  background: Background,
  preview: {
    idle: createStaticUrl(`static/themes/greenhouse/greenhouse_theme_idle.jpg`),
    hover: createStaticUrl(
      `static/themes/greenhouse/greenhouse_theme_hover.gif`,
    ),
  },
};

export default Export;
