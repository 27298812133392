/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import type { TextT } from '@mentimeter/ragnar-ui';
import { Text as T } from '@mentimeter/ragnar-ui';
import { DSC } from '../../canvas/config';
import { useQuestion } from '../../data-mediation/question';

export interface FlexibleTextT extends TextT {
  fixed?: boolean;
  fontOffset?: number;
}

const FONT_SIZE_THRESHOLD = DSC.fontSizes.length;

export const FlexibleText = React.memo<FlexibleTextT>(
  ({ fontSize, fixed, fontOffset, ...props }: FlexibleTextT) => {
    const { layout } = useQuestion();
    const computedFontSize = React.useMemo(() => {
      if (fixed) return fontSize;
      let computedFontSize = fontSize;
      if (typeof computedFontSize === 'number') {
        const tmp =
          computedFontSize +
          (fontOffset ?? 0) +
          (layout?.font_size_offset ?? 0);
        computedFontSize = tmp < FONT_SIZE_THRESHOLD ? tmp : fontSize;
      }
      return computedFontSize;
    }, [fixed, fontSize, fontOffset, layout?.font_size_offset]);
    return <T fontSize={computedFontSize} {...props} />;
  },
);
