import { trackUser } from '@api/tracking/client';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { HotkeyProvider } from '@mentimeter/hotkeys';
import type { SlideState } from '@mentimeter/presentation-state';
import { PresentationContext } from '@mentimeter/question-modules-contexts';
import {
  importPresentationDynamicallyLazy,
  PresentationModuleSuspense,
} from '@mentimeter/question-modules-importer';
import {
  KEYSTROKES,
  MissingQuestionModuleError as LoadingError,
} from '@mentimeter/question-modules-shared';
import type {
  PresentationSDKT,
  SetSlideState,
} from '@mentimeter/question-modules-types';
import type { DSC } from '@mentimeter/ragnar-dsc';
import { ThemeContext } from '@mentimeter/ragnar-react';
import { ScreenshotTrigger } from '@mentimeter/screenshots/ScreenshotTrigger';
import React from 'react';
import {
  useCountdownState,
  useOptionalPace,
  usePresentation,
  useQfa as useQfaSfinx,
  useQuestion,
  useSessions,
  useConfig as useSfinxConfig,
  useDispatch as useSfinxDispatch,
  useSeries as useSfinxSeries,
  useSlide,
  useSplit,
  useTranslate,
  useTrendsResults,
  useVoteCode,
} from '../data-mediation';
import { useProfanities } from '../data-mediation/profanities';
import { useQuestionModuleResults } from '../data-mediation/results';
import fontOffset from '../slide-layout/fontOffset';
import { Controls } from '../toolbar/Controls';
import profanityFilter from '../utils/profanityFilter';
import { useInteractiveCanvas } from './interactive-canvas';
import { useModeration as useSfinxModeration } from './moderation';
import { useTestVotesState } from './presentation-actions/testVotes';
import { useSegmentation as useSfinxSegmentation } from './segmentation';
import { useThemeComponent } from './themes/theme-components';

const useConfig = (readonly: boolean) => {
  const { mode, enableAnimations, enableHotkeys, enableEmbedSlides } =
    useSfinxConfig();
  return {
    mode,
    enableAnimations,
    enableHotkeys,
    enableEmbedSlides,
    readonly,
  };
};

const useTestVotes = () => {
  const { testVotesShowing } = useTestVotesState();
  return { testVotesShowing };
};

const useVotingInstructions = () => {
  const { baseUrl, votingUrl } = useSfinxConfig();
  const { vote_key: voteKey } = useSfinxSeries();
  const voteCode = useVoteCode();
  return {
    baseUrl,
    votingUrl,
    voteKey,
    voteCode,
  };
};

const useTrends = () => {
  const { showTrends } = usePresentation();
  const results = useTrendsResults();

  return {
    results,
    showTrends,
  };
};

const useTheme = () => React.useContext(ThemeContext) as DSC;

export const useModeration: PresentationSDKT['useModeration'] = () => {
  const { toggleWord, moderationEnabled } = useSfinxModeration();
  const question = useQuestion();
  if (question.type !== 'open' && question.type !== 'wordcloud') {
    throw new Error(`Cant use moderation in question of type ${question.type}`);
  }
  return {
    toggleWord,
    showUi: moderationEnabled,
  };
};

const useSegmentation = () => {
  const { segments, segmentedBy } = useSfinxSegmentation();
  const question = useQuestion();
  if (question.type !== 'choices') {
    throw new Error(
      `Cant use segmentation in question of type ${question.type}`,
    );
  }

  return {
    segments,
    isSegmented: segmentedBy !== undefined,
    segmentedQuestionTitle: segmentedBy?.question,
  };
};

const usePace: PresentationSDKT['usePace'] = () => {
  const {
    state: { step, hasNextSlide } = { step: 0, hasNextSlide: false },
    actions: { next, nextStep, nextSlide } = {
      next: () => {},
      nextStep: () => {},
      nextSlide: () => {},
    },
  } = useOptionalPace() ?? {};
  return {
    next: React.useCallback(() => next('SDK'), [next]),
    nextStep: React.useCallback(() => nextStep('SDK'), [nextStep]),
    nextSlide: React.useCallback(() => nextSlide('SDK'), [nextSlide]),
    hasNextSlide,
    step,
  };
};

const useSlideState: PresentationSDKT['useSlideState'] = <
  T extends SlideState,
>() => {
  const question = useQuestion();
  const { state, actions } = useOptionalPace() ?? {};
  const setSlideStateAction = actions?.setSlideState;

  const slideState = state?.slideStates?.[question.public_key] ?? {};

  const setSlideState = React.useCallback<SetSlideState<T>>(
    (newSlideState) =>
      setSlideStateAction?.(question.public_key, newSlideState),
    [setSlideStateAction, question.public_key],
  );

  return [slideState as T, setSlideState];
};

// We partly pass useSeries to the SDK, because we need some qfa data from series.
// And the idea is to move this data to the QFA endpoints in the future.
// But we don't want to pass more series data to SDK, so please if you
// are thinking to do it, change your mind or share your reasons with PX team.
const useSeries = () => {
  const {
    qfa_intercom_enabled,
    qfa_moderation_enabled,
    participation_identity_mode,
  } = useSfinxSeries();
  return {
    qfa_intercom_enabled,
    qfa_moderation_enabled,
    participation_identity_mode,
  };
};

const useActiveSession = () => {
  const { presentationSessions } = useSessions();
  return presentationSessions.find((session) => session.current)?.id ?? 0;
};

/**
 * Only used in Quiz Leaderboard player names at the moment.
 * TODO: Should be a backend feature.
 * @deprecated
 */
const useProfanityFilter = () => {
  const profanities = useProfanities();
  return React.useCallback(
    (source: string) => profanityFilter(source, profanities),
    [profanities],
  );
};

interface PropsT {
  readonly?: boolean;
}

const Module = React.memo<PropsT>(({ readonly = false }: PropsT) => {
  const { renderSlideControls } = useSfinxConfig();
  const question = useQuestion();

  const Presentation = React.useMemo(
    () => importPresentationDynamicallyLazy(question.module_id),
    [question.module_id],
  );
  const { Text, StringParser } = useThemeComponent();
  const InteractiveCanvasComponents = useInteractiveCanvas();

  const { Live_Identified_Responses } = useSplit();

  const SDK = React.useMemo<PresentationSDKT>(
    () => ({
      splits: {
        Live_Identified_Responses,
      },
      useTheme,
      useQuestion,
      useSlide,
      useQfa: useQfaSfinx,
      useSeries,
      useConfig: () => useConfig(readonly),
      useTrends,
      useDispatch: useSfinxDispatch,
      useResults: useQuestionModuleResults,
      useCountdownState,
      useTranslate,
      ThemeStyled: {
        FlexibleText: Text,
        StringParser,
      },
      Utils: { fontOffset },
      track: trackUser,
      useModeration,
      usePace,
      useVotingInstructions,
      useSegmentation,
      useActiveSession,
      useProfanityFilter,
      useTestVotes,
      useSlideState,
      InteractiveCanvasComponents,
    }),
    [
      Live_Identified_Responses,
      Text,
      StringParser,
      InteractiveCanvasComponents,
      readonly,
    ],
  );

  return (
    <ErrorBoundary feature="slides" fallback={<LoadingError hasLink={true} />}>
      <HotkeyProvider keyStrokes={KEYSTROKES}>
        <PresentationContext.Provider value={SDK}>
          <PresentationModuleSuspense>
            <Presentation key={question.id} />
            <ScreenshotTrigger />
          </PresentationModuleSuspense>
          {renderSlideControls && <Controls question={question} />}
        </PresentationContext.Provider>
      </HotkeyProvider>
    </ErrorBoundary>
  );
});

export default Module;
