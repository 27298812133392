/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { trackUser } from '@api/tracking/client';
import { useQuestion } from '../question';
import { useDispatch } from '../../misc/dispatcher';
import { useOptionalPace } from '../../misc/pace';

const createDeadline = (seconds: number, currentDeadline: number) => {
  if (seconds === 0) return 0;
  if (currentDeadline > 0) return currentDeadline + seconds * 1000;
  return Date.now() + seconds * 1000;
};

export interface CountdownContext {
  currentCountdown: number;
  setCurrentCountdown: (
    seconds: number,
    payload?: {
      closeVoting?: boolean;
    },
  ) => void;
  trackSetCurrentdown: ({
    seconds,
    placement,
  }: {
    seconds: number;
    placement: string;
  }) => void;
}

const defaultState: CountdownContext = {
  currentCountdown: 0,
  setCurrentCountdown: () => {},
  trackSetCurrentdown: () => {},
};
const context = React.createContext<CountdownContext>(defaultState);
context.displayName = 'CountdownContext';

export const CountdownContextProvider = context.Provider;

export const CountdownProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const { id, active, public_key } = useQuestion();
  const { state, actions } = useOptionalPace() ?? {};
  const currentCountdown =
    state?.slideStates?.[public_key]?.currentCountdown || 0;
  const setSlideStateAction = actions?.setSlideState;

  const setCurrentCountdown: CountdownContext['setCurrentCountdown'] =
    React.useCallback(
      (seconds, payload = {}) => {
        setSlideStateAction?.(public_key, ({ currentCountdown = 0 }) => ({
          currentCountdown: createDeadline(seconds, currentCountdown),
        }));

        if (payload.closeVoting) {
          dispatch({
            type: 'SET_QUESTION_SETTING',
            payload: { id, key: 'active', value: false },
          });
        }
      },
      [dispatch, id, public_key, setSlideStateAction],
    );

  const trackSetCurrentdown = ({
    seconds,
    placement,
  }: {
    seconds: number;
    placement: string;
  }) => {
    trackUser({
      event: 'Used countdown',
      properties: {
        'Countdown seconds': seconds,
        placement,
        context: 'Presentation view',
      },
    });
  };

  React.useEffect(() => {
    if (!active) {
      setCurrentCountdown(0);
    }
  }, [active, setCurrentCountdown]);

  return (
    <CountdownContextProvider
      value={{
        setCurrentCountdown,
        trackSetCurrentdown,
        currentCountdown: !active ? 0 : currentCountdown,
      }}
    >
      {children}
    </CountdownContextProvider>
  );
};

export const useCountdownState = () => React.useContext(context);
