import { Box, ImageProgressive } from '@mentimeter/ragnar-ui';
import { type PropsWithChildren } from 'react';
import type { Layout } from '@mentimeter/http-clients';
import { CanvasPlaceholder } from '../misc/assets/CanvasPlaceholder';

interface SideBySideImageProps {
  imageUrl: string | undefined | null;
  imageAltText?: string | undefined;
  layoutType: Layout['type'] | undefined;
  imageWidth?: '50%' | '35%' | undefined;
}

export const SideBySideImage = ({
  children,
  imageUrl,
  imageAltText,
  layoutType,
  imageWidth,
}: PropsWithChildren<SideBySideImageProps>) => {
  const isSideBySide =
    layoutType === 'side-by-side' || layoutType === 'side-by-side-right';
  const flexDirection =
    layoutType === 'side-by-side-right' ? 'row-reverse' : 'row';

  return (
    <Box flexDirection={flexDirection} width="100%" height="100%">
      {isSideBySide && (
        <Box
          height="100%"
          width={imageWidth}
          justifyContent="center"
          alignItems="center"
          data-testid="side-by-side-image"
          px="space4"
        >
          {imageUrl ? (
            <ImageProgressive src={imageUrl} alt={imageAltText || ''} />
          ) : (
            <Box width="32rem" height="32rem" p="6rem" bg="#DBDCE1">
              <CanvasPlaceholder />
            </Box>
          )}
        </Box>
      )}
      <Box flex="1" height="100%" justifyContent="center" alignItems="center">
        {children}
      </Box>
    </Box>
  );
};
