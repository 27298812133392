import { createStaticUrl } from '../utils';
import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT<React.ComponentProps<typeof Background>> = {
  id: 'nature',
  title: 'Nature',
  background: Background,
  preview: {
    idle: createStaticUrl('/static/themes/nature/nature_theme_idle.jpg'),
    hover: createStaticUrl('/static/themes/nature/nature_theme_hover.gif'),
  },
};

export default Export;
