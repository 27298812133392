import type { PresentationSplitValuesT } from '@mentimeter/question-modules-types';
import * as React from 'react';

export interface SplitState extends PresentationSplitValuesT {
  isReady: boolean;
  Live_Identified_Responses: 'on' | 'off' | 'control' | 'not_ready';
}

const context = React.createContext<SplitState | undefined>(undefined);

export const SplitProvider = context.Provider;
context.displayName = 'SplitContext';

export const useSplit = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('Split context used before initialized');
  }
  return data;
};
