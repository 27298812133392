import * as React from 'react';
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../utils/constants';
import { Resizer } from './Resizer';

type RenderT = (arg0: {
  transform: string;
  transformOrigin: string;
}) => React.ReactNode;

export interface ContainerT {
  width: number;
  height: number;
  scaleToWindow?: boolean;
  transformOrigin?: string | undefined;
  useOffset: boolean;
  children: RenderT;
  onResize?: ((scaleFactor: number) => void) | undefined;
}

interface CalculateT {
  widthElement: number;
  heightElement: number;
  widthContainer: number;
  heightContainer: number;
}

const calculate = ({
  widthElement,
  heightElement,
  widthContainer,
  heightContainer,
}: CalculateT) => {
  const containerRatio = widthContainer / heightContainer;
  const componentRatio = widthElement / heightElement;
  let ratio = widthContainer / widthElement;
  if (containerRatio > componentRatio) ratio = heightContainer / heightElement;
  return { width: widthContainer, height: heightContainer, scale: ratio };
};

const C = React.memo<ContainerT>(
  ({
    width,
    height,
    scaleToWindow,
    transformOrigin,
    useOffset,
    children,
    onResize,
  }: ContainerT) => {
    // The container has two states: scaleToWindow (fullScale) and default (scale)
    const [fullScale, setFullScale] = React.useState({
      width: 0,
      height: 0,
      scale: 0,
    });
    const [scale, setScale] = React.useState({
      width: 0,
      height: 0,
      scale: 0,
      top: 0,
      left: 0,
    });

    const resize = React.useRef(() => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      setFullScale(
        calculate({
          widthElement: width,
          heightElement: height,
          widthContainer: windowWidth,
          heightContainer: windowHeight,
        }),
      );
    });

    React.useEffect(() => {
      const resizeFunction = resize.current;
      if (scaleToWindow && window) {
        window.addEventListener('resize', resizeFunction);
        resizeFunction();
      } else {
        window.removeEventListener('resize', resizeFunction);
      }
      return () => {
        window.removeEventListener('resize', resizeFunction);
      };
    }, [scaleToWindow]);

    const handleResize = React.useCallback(
      (
        top: number,
        left: number,
        widthContainer: number,
        heightContainer: number,
      ) => {
        const scale = {
          top,
          left,
          ...calculate({
            widthElement: width,
            heightElement: height,
            widthContainer,
            heightContainer,
          }),
        };
        setScale(scale);
        onResize?.(scale.scale);
      },
      [height, width, onResize],
    );

    // Creating the transform string
    const style = React.useMemo(() => {
      const verticalOffset = `translateY(${-scale.top}px) translateY(${
        (fullScale.height - height * fullScale.scale) / 2
      }px)`;
      const horizontalOffset = `translateX(${-scale.left}px) translateX(${
        (fullScale.width - width * fullScale.scale) / 2
      }px)`;
      const transformString = `${scaleToWindow ? horizontalOffset : ''} ${
        scaleToWindow ? verticalOffset : ''
      } scale(${scaleToWindow ? fullScale.scale : scale.scale})`;

      return {
        transformOrigin: transformOrigin || 'top left',
        transform: transformString,
      };
    }, [
      fullScale.height,
      fullScale.scale,
      fullScale.width,
      scaleToWindow,
      height,
      scale.left,
      scale.scale,
      scale.top,
      width,
      transformOrigin,
    ]);

    return (
      <>
        <Resizer callback={handleResize} useOffset={useOffset} />
        {children(style)}
      </>
    );
  },
);

interface Container {
  children: RenderT;
  scaleToWindow?: boolean;
  transformOrigin?: string;
  useOffset?: boolean;
  onResize?: ((scaleFactor: number) => void) | undefined;
}

/**
 * This component calculates how much the canvas needs to scale compared to either a container or the browser window.
 * @param children
 * @param transformOrigin
 * @param useOffset use `offsetWidth`/`offsetHeight`-family of properties instead of `getBoundingClientRect`. More info on the difference: https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements#how_much_room_does_it_use_up
 * @param scaleToWindow if `true` will ignore containers and always scale to window size
 * @param onResize is called every time the container is resized, with the scale factor as a parameter */
export const Container = ({
  children,
  scaleToWindow = false,
  transformOrigin,
  useOffset = false,
  onResize,
}: Container) => {
  return (
    <C
      width={CANVAS_WIDTH}
      height={CANVAS_HEIGHT}
      scaleToWindow={scaleToWindow}
      transformOrigin={transformOrigin}
      useOffset={useOffset}
      onResize={onResize}
    >
      {children}
    </C>
  );
};
