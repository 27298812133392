/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { CURRENT_SESSION_INDEX } from '../../utils/constants';
import type { SfinxSessionState } from './types';

const defaultState: SfinxSessionState = {
  activeSession: CURRENT_SESSION_INDEX,
  presentationSessions: [],
};

const context = React.createContext<SfinxSessionState>(defaultState);
context.displayName = 'SessionContext';
export const SessionsProvider = context.Provider;

export const useSessions = () => {
  const { presentationSessions, activeSession } = React.useContext(context);

  return {
    activeSession,
    presentationSessions: React.useMemo(
      () => presentationSessions ?? [],
      [presentationSessions],
    ),
  };
};

export const TestSessionsProvider = ({
  children,
  ...rest
}: React.PropsWithChildren<Partial<SfinxSessionState>>) => {
  return (
    <SessionsProvider value={{ ...defaultState, ...rest }}>
      {children}
    </SessionsProvider>
  );
};
