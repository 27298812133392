import type { Slide as PresentationSlide } from '@mentimeter/presentation-schema/api-types-overrides';
import type { Slide as EditorSlide } from '@mentimeter/editor-schema/api-types-overrides';
import * as React from 'react';
import type { Question } from '@mentimeter/http-clients';

const context = React.createContext<Question | undefined>(undefined);

export const useQuestion = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('SfinxError: no question data found');
  }
  return data;
};

export const useSlide = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('SfinxError: no question data found');
  }
  return data as unknown as EditorSlide | PresentationSlide;
};
export const QuestionProvider = context.Provider;
