import * as React from 'react';
import type { SfinxQfaState } from './types';

/**
 * @deprecated We want to move away from setting default data
 */
export const defaultQfaState: SfinxQfaState = {
  // States
  loading: false,
  error: false,
  initialized: true,
  enabled: false,

  // Configs and settings
  moderationUrl: '',

  // Meta
  newAvailable: true,
  totalQuestions: 0,
  totalUpvotes: 0,

  // Data
  activeId: 0,
  questions: {},
  ids: {
    pending: { items: [], total: 0 },
    unanswered: { items: [], total: 0 },
    rejected: { items: [], total: 0 },
    archived: { items: [], total: 0 },
    all: { items: [], total: 0 },
  },
  askedOnSlide: '',
  toggleQfa: () => {},
  actions: {
    setOverlay: () => {},
    nextQuestion: () => {},
    previousQuestion: () => {},
    markAsAnswered: () => {},
    restoreQuestion: () => {},
    deleteAllAnswered: () => {},
    setActiveQuestion: () => {},
  },
};

const context = React.createContext<SfinxQfaState>(defaultQfaState);
context.displayName = 'QfaContext';
export const QfaProvider = context.Provider;

export const useQfa = () => React.useContext(context);
