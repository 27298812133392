import type * as React from 'react';
import { createComponent } from '@mentimeter/ragnar-react';

const variants = [
  {
    // IE11 flicker fix
    '0.01%': {
      transform: 'translate(0,0)',
      opacity: 0,
    },
    '10%': {
      opacity: 1,
    },
    '100%': {
      transform: 'translate(-200px, -200px)',
      opacity: 0,
    },
  },
  {
    '0.01%': {
      transform: 'translate(0,0)',
      opacity: 0,
    },
    '10%': {
      opacity: 1,
    },
    '90%': {
      opacity: 0,
    },
    '100%': {
      transform: 'translate(-400px, -240px)',
      opacity: 0,
    },
  },
  {
    '0.01%': {
      transform: 'translate(0,0)',
      opacity: 0,
    },
    '10%': {
      opacity: 1,
    },
    '80%': {
      opacity: 0,
    },
    '100%': {
      transform: 'translate(-300px, -540px)',
      opacity: 0,
    },
  },
  {
    '0.01%': {
      transform: 'translate(0,0)',
      opacity: 0,
    },
    '10%': {
      opacity: 1,
    },
    '90%': {
      opacity: 0,
    },
    '100%': {
      transform: 'translate(-450px, -300px)',
      opacity: 0,
    },
  },
];

const components = variants.map<React.ComponentType<any>>((v) => {
  const keyframes = () => v;

  return createComponent(
    ({ duration = 2000, delay = 0 }, renderer) => ({
      animation: `${renderer.renderKeyframe(
        keyframes,
        undefined,
      )} ${duration}ms ${delay}ms ease-in forwards`,
    }),
    'div',
  );
});

export default components;
