import React from 'react';

export const useImageLoader = (src: string) => {
  const srcs = React.useMemo(() => [src], [src]);
  return useImagesLoader(srcs);
};

export const useImagesLoader = (srcs: string[]) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    setHasLoaded(false);
    const images: HTMLImageElement[] = [];
    const loadImages = async () => {
      await Promise.all(
        srcs.map(
          (src) =>
            new Promise((resolve) => {
              const image = new Image();

              image.onload = (_e) => {
                resolve(src);
              };

              image.src = src;

              images.push(image);
            }),
        ),
      );

      setHasLoaded(true);
    };
    loadImages();

    return () => {
      images.forEach((image) => (image.onload = null));
    };
  }, [srcs]);

  return hasLoaded;
};
