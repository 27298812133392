import * as React from 'react';
import { Image, Box } from '@mentimeter/ragnar-ui';
import { toContinuous } from '../utils/continuous';

const bg = `http://static.mentimeter.com/static/themes/space/background.jpg`;
const globe = `http://static.mentimeter.com/static/themes/space/globe.png`;
const glow = `http://static.mentimeter.com/static/themes/space/globe-glow.png`;
// const comet = `http://static.mentimeter.com/static/themes/space/comet.png`;

const Space = ({
  currentIndex = 0,
  total = 1,
  progress,
}: {
  progress: number;
  currentIndex: number;
  total: number;
}) => {
  const p = toContinuous(total, currentIndex, progress);
  const rotation = -8 + p * 16;
  return (
    <Box
      position="absolute"
      alignItems="center"
      zIndex={1}
      top={0}
      left={0}
      bottom={0}
      right={0}
      bg="black"
      overflow="hidden"
      backgroundSize="cover"
      backgroundImage={`url(${bg})`}
      backgroundPosition="center"
    >
      <Box
        position="absolute"
        width={800}
        height={200}
        bottom={0}
        alignItems="stretch"
      >
        <Box position="absolute">
          <Image
            src={globe}
            alt="A globe"
            style={{
              transition: `transform 2s ease-in-out`,
              transform: `rotate(${rotation}deg)`,
            }}
          />
        </Box>
        <Box position="absolute">
          <Image src={glow} alt="A glowing globe" />
        </Box>
      </Box>
    </Box>
  );
};

export default Space;
