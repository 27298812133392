import type {
  PreviewResultsInputs,
  RankingT,
} from '@mentimeter/question-modules-types';

const INITIAL_RESULTS: RankingT = {
  respondents: 0,
  type: 'ranking',
  results: [],
  isPlaceholderResults: false,
  timestamp: 0,
  segments: [],
};

export const getPreviewResultsIteration = ({
  previous = INITIAL_RESULTS,
  iteration,
  extras: { choices },
}: PreviewResultsInputs<RankingT>): RankingT => {
  const output = { ...previous };

  if (iteration === 0 || previous.results.length === 0) {
    output.results = choices.map(({ label, id }) => ({ label, id, score: 0 }));
  } else {
    output.respondents += Math.floor(Math.random() * 3) + 1;
    let totalScore = 100;
    output.results = previous.results.map(({ label, id }, i) => {
      const score =
        i === choices.length ? totalScore : Math.random() * totalScore;
      totalScore -= score;
      return { label, id, score };
    });
  }
  return output;
};
