import { create } from 'zustand';
import type { Reaction } from '@mentimeter/http-clients';

interface ReactionData {
  timestamp: number;
  amount: number;
  colorIndex: number;
}

export interface ReactionQueueItem {
  id: string;
  index: number;
  duration: number;
  delay: number;
  colorIndex: number;
}

export type RealtimeReactionQueues = Partial<
  Record<Reaction, ReactionQueueItem[]>
>;

interface ReactionStore {
  reactions: RealtimeReactionQueues;
  addReaction: (reaction: Reaction, reactionData: ReactionData) => void;
  popFromQueue: (reaction: Reaction, reactionQueueId: string) => void;
  clearReactions: () => void;
}

const REACTION_MAX_DELAY = 250;
const REACTION_DURATION = 2000;
const MAX_REACTIONS = 30;

export const useRealtimeReactionsStore = create<ReactionStore>((set) => ({
  reactions: {},
  addReaction(reaction, reactionData) {
    const reactionQueueData: ReactionQueueItem[] = [];
    for (let index = 0; index < reactionData.amount; index++) {
      const delay = Math.floor(Math.random() * REACTION_MAX_DELAY);
      reactionQueueData.push({
        id: `${reaction}-${reactionData.timestamp}-${index}`,
        duration: REACTION_DURATION + delay,
        delay,
        colorIndex: reactionData.colorIndex,
        index,
      });
    }
    set((state) => {
      const nextQueue = state.reactions[reaction]
        ? [...state.reactions[reaction]!, ...reactionQueueData]
        : reactionQueueData;

      return {
        reactions: {
          ...state.reactions,
          [reaction]: nextQueue.slice(0, MAX_REACTIONS),
        },
      };
    });
  },
  popFromQueue(reaction, reactionQueueId) {
    set((state) => {
      const reactionQueue = state.reactions[reaction];
      if (!reactionQueue) {
        return state;
      }
      return {
        reactions: {
          ...state.reactions,
          [reaction]: reactionQueue.filter((r) => r.id !== reactionQueueId),
        },
      };
    });
  },
  clearReactions() {
    set(() => ({
      reactions: {},
    }));
  },
}));
