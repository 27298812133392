import * as React from 'react';
import type { Session } from '@mentimeter/http-clients';

interface ResponseSession {
  session: number;
  participantCount: number;
  responses: Array<ResponseResult>;
}

interface ResponseResult {
  interactiveContentChoiceId: string;
  legacyChoiceId: number;
  title: string;
  responses: Array<number>;
}

export interface SfinxTrendsState {
  presentationSessions: Session[] | undefined;
  activeQuestionSessions: Array<ResponseSession> | undefined;
}

interface PresentationSession {
  id: number;
  current: boolean;
  label: string;
  data: ResponseSession | undefined;
}

const defaultState: SfinxTrendsState = {
  presentationSessions: [],
  activeQuestionSessions: [],
};

const context = React.createContext<SfinxTrendsState>(defaultState);
context.displayName = 'TrendsContext';
export const TrendsProvider = context.Provider;

export const useTrendsResults = () => {
  const { activeQuestionSessions, presentationSessions } =
    React.useContext(context);

  return React.useMemo<Array<PresentationSession>>(
    () =>
      presentationSessions?.map((s) => ({
        id: s.id,
        current: Boolean(s.current),
        label: s.current ? 'Current' : `Session ${s.id + 1}`,
        data: activeQuestionSessions?.find((q) => q.session === s.id) ?? {
          participantCount: 0,
          responses: [],
          session: s.id,
        },
      })) ?? [],
    [presentationSessions, activeQuestionSessions],
  );
};
