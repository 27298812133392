import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../Box';
import type { ImageT } from '../Image';

export type ImageStretchT = ImageT;

const ImageStretch = ({ src, alt }: ImageStretchT) => {
  return (
    <Box
      role={alt === '' ? undefined : 'img'}
      aria-label={alt}
      style={
        {
          '--image-stretch-image-url': `url(${src})`,
        } as React.CSSProperties
      }
      className={clsx([
        'w-full',
        'h-full',
        'bg-center',
        'bg-no-repeat',
        'bg-contain',
        'bg-[image:var(--image-stretch-image-url)]',
      ])}
    />
  );
};

export { ImageStretch };
