import React, { useEffect } from 'react';
import { useDelayedState } from '@mentimeter/react-hooks';
import { LoadingPlaceholder } from './LoadingPlaceholder';

const PLACEHOLDER_DELAY_IN_MS = 200;

export const DelayedLoadingPlaceholder = () => {
  const [show, setShow] = useDelayedState(false);

  useEffect(() => {
    setShow(true, PLACEHOLDER_DELAY_IN_MS);
  }, [setShow]);

  if (!show) {
    return null;
  }

  return <LoadingPlaceholder />;
};
