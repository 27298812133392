import { useCallback, useMemo } from 'react';
import type {
  QuestionChoice,
  QuestionType,
  QuizPlayerScore,
} from '@mentimeter/http-clients';
import type { QuizPlayersScoreData } from '@mentimeter/core-hooks';
import { usePreviewResultsIterator } from './usePreviewResultsIterator';

export type QuizResultWithPreview = QuizPlayersScoreData & {
  isPreviewResults?: boolean;
};

export const useQuizPreviewResults = ({
  previewResultsEnabled,
  choices,
  questionType,
}: {
  previewResultsEnabled: boolean;
  choices: QuestionChoice[];
  questionType: QuestionType;
}) => {
  const firstCorrectAnswer = choices[0]?.label; // choices in open are correct answers set by the presenter
  const correctAnswer = firstCorrectAnswer ?? 'Maybe correct answer';

  const staticPreviewResult: QuizResultWithPreview = useMemo(
    () => ({
      data: [
        {
          player: {
            identifier: 'preview-results-player-1',
            name: 'Shammy',
            emojiShortname: ':four_leaf_clover:',
            emojiName: 'Shammy',
            index: 0,
          },
          score: {
            total: {
              totalScore: 500,
              position: 1,
            },
            question: {
              score: 0,
              correctAnswer: false,
              choiceId: choices[0]?.id ?? 0,
              answer: 'Incorrect answer',
            },
          },
        },
        {
          player: {
            identifier: 'preview-results-player-2',
            name: 'Ted',
            emojiShortname: ':bear:',
            emojiName: 'Ted',
            index: 2,
          },
          score: {
            total: {
              totalScore: 100,
              position: 3,
            },
            question: {
              score: 50,
              correctAnswer: true,
              choiceId: choices[1]?.id ?? 0,
              // open
              answer: correctAnswer,
              markedCorrect: true,
            },
          },
        },
        {
          player: {
            identifier: 'preview-results-player-3',
            name: 'Pingu',
            emojiShortname: ':penguin:',
            emojiName: 'Pingu',
            index: 3,
          },
          score: {
            total: {
              totalScore: 300,
              position: 2,
            },
            question: {
              score: 50,
              correctAnswer: false, // used only in voting
              choiceId: choices[1]?.id ?? 0, // used in quiz choices
              answer: 'Not so correct answer', // used in quiz open
            },
          },
        },
      ] satisfies QuizPlayerScore[],
      error: null,
      isValidating: false,
      isLoading: false,
      isPreviewResults: true,
      mutate: () => {
        return Promise.resolve(undefined);
      },
      fetchResults: () => {
        return Promise.resolve(undefined);
      },
    }),
    [choices, correctAnswer],
  );

  const emptyResult: QuizResultWithPreview = useMemo(
    () => ({
      ...staticPreviewResult,
      data: [],
    }),
    [staticPreviewResult],
  );

  const getNextResults = useCallback(
    (iteration: number, _: any): QuizResultWithPreview => {
      if (questionType === 'quiz_leaderboard') {
        return staticPreviewResult;
      }
      if (questionType === 'quiz_open') {
        return iteration % 2 === 0 ? staticPreviewResult : emptyResult;
      }
      if (iteration % 2 === 0) {
        const SCORE_MIN = 0;
        const SCORE_MAX = 100;
        const getRandomScore = () =>
          Math.floor(Math.random() * (SCORE_MAX - SCORE_MIN + 1)) + SCORE_MIN;
        const getRandomChoice = () =>
          Math.floor(Math.random() * choices.length);
        return {
          ...staticPreviewResult,
          data: staticPreviewResult.data?.map((player) => ({
            ...player,
            score: {
              ...player.score,
              question: {
                answer: '',
                ...player.score.question,
                score: getRandomScore(),
                choiceId: choices[getRandomChoice()]?.id ?? 0,
              },
            },
          })),
        };
      } else {
        return emptyResult;
      }
    },
    [questionType, emptyResult, staticPreviewResult, choices],
  );

  const getIntervalOpen = useCallback((iteration: number) => {
    if (iteration % 2 === 0) {
      return 4000;
    } else {
      return 600;
    }
  }, []);

  const getIntervalChoices = useCallback((iteration: number) => {
    if (iteration === 1) {
      return 300;
    }
    if (iteration % 2 === 0) {
      return 3000;
    } else {
      return 1500;
    }
  }, []);

  return usePreviewResultsIterator<QuizResultWithPreview>(
    previewResultsEnabled,
    getNextResults,
    questionType === 'quiz_open' ? getIntervalOpen : getIntervalChoices,
  );
};
