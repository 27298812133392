/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { default as defaultLang } from '../../../languages/data/en.json';

type LangJSON = string | { [key: string]: LangJSON };

const flattenLanguageObject = (
  obj: { [key: string]: LangJSON },
  parentKey?: string,
) => {
  let result: Record<string, string> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const _key = parentKey ? parentKey + '.' + key : key;
    if (typeof value === 'object') {
      result = { ...result, ...flattenLanguageObject(value, _key) };
    } else if (value) {
      result[_key] = value;
    }
  });

  return result;
};

// Dictionary copied from `en.json` but flattened
const defaultDictionary = flattenLanguageObject(defaultLang);

export type SfinxTranslationState = typeof defaultDictionary;
export type TranslationDictionaryKey = keyof SfinxTranslationState;
export type SfinxTranslationFn = (
  translationKey: TranslationDictionaryKey,
  data?: Record<string, string | number>,
) => string;

const fallbackTranslationFunction: SfinxTranslationFn = (
  translationKey,
  data,
) => {
  let translatedString: string =
    defaultDictionary[translationKey] || translationKey;

  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      const regex = new RegExp('\\${\\s*' + key + '\\s*}', 'gmi');
      translatedString = translatedString.replace(regex, value.toString());
    });
  }

  return translatedString;
};

export const defaultState = fallbackTranslationFunction;

const context = React.createContext<SfinxTranslationFn>(defaultState);
context.displayName = 'TranslationContext';

const Provider = context.Provider;

export const TranslationProvider = ({
  children,
  translate,
}: {
  children: React.ReactNode;
  translate?: SfinxTranslationFn;
}) => <Provider value={translate || defaultState}>{children}</Provider>;

export const useTranslate = () => React.useContext(context);
