import React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui';
import { Box, Svg } from '@mentimeter/ragnar-ui';
import { useRagnar } from '@mentimeter/ragnar-react';

export const CanvasPlaceholder = (props: BoxT) => {
  const { theme } = useRagnar();
  const { colors } = theme;

  return (
    <Box width="100%" height="100%" {...props}>
      <Svg viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M76.0768 121.508C109.358 121.508 136.337 94.5286 136.337 61.2477C136.337 27.9668 109.358 0.987244 76.0768 0.987244C42.7959 0.987244 15.8164 27.9668 15.8164 61.2477C15.8164  94.5286 42.7959 121.508 76.0768 121.508Z"
          fill={colors.onNeutralWeakest}
          opacity="0.5"
        />
        <path
          d="M121.271 136.575H0.75L61.0104 61.2476L121.271 136.575Z"
          fill={colors.onNeutralWeakest}
        />
        <path
          d="M151.401 136.574L61.0105 136.569L106.207 76.3141L151.401 136.574Z"
          fill={colors.onNeutral}
        />
        <path
          d="M151.401 148.527H0.75V151.638H151.401V148.527Z"
          fill={colors.onNeutral}
        />
      </Svg>
    </Box>
  );
};
