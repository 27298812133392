import { getPreviewResultsIteration as wordcloud } from '@mentimeter/question-module-wordcloud/getPreviewResultsIteration';
import { getPreviewResultsIteration as open } from '@mentimeter/question-module-open/getPreviewResultsIteration';
import { getPreviewResultsIteration as prioritisation } from '@mentimeter/question-module-100-points/getPreviewResultsIteration';
import { getPreviewResultsIteration as choices } from '@mentimeter/question-module-choices/getPreviewResultsIteration';
import { getPreviewResultsIteration as scales } from '@mentimeter/question-module-scales/getPreviewResultsIteration';
import { getPreviewResultsIteration as numericalQuestion } from '@mentimeter/question-module-numerical-question/getPreviewResultsIteration';
import {
  getPreviewResultsIteration as pinOnImage,
  previewResultsInterval as pinOnImageInterval,
} from '@mentimeter/question-module-pin-on-image/getPreviewResultsIteration';
import { getPreviewResultsIteration as ranking } from '@mentimeter/question-module-ranking/getPreviewResultsIteration';
import {
  getPreviewResultsIteration as rating,
  previewResultsInterval as ratingInterval,
} from '@mentimeter/question-module-rating/getPreviewResultsIteration';
import type { ModuleId } from '@mentimeter/http-clients';
import type { PreviewResultsIterationGetter } from '../../question-modules-types/src/previewResults';

export const importPreviewResults = (
  moduleId: ModuleId,
): {
  getPreviewResultsIteration: PreviewResultsIterationGetter<any> | null;
  timeToNextIteration?: (iteration: number) => number;
} => {
  switch (moduleId) {
    case '100-points':
      return { getPreviewResultsIteration: prioritisation };
    case 'choices':
      return { getPreviewResultsIteration: choices };
    case 'numerical-question':
      return { getPreviewResultsIteration: numericalQuestion };
    case 'open':
      return { getPreviewResultsIteration: open };
    case 'pin-on-image':
      return {
        getPreviewResultsIteration: pinOnImage,
        timeToNextIteration: pinOnImageInterval,
      };
    case 'ranking':
      return { getPreviewResultsIteration: ranking };
    case 'rating':
      return {
        getPreviewResultsIteration: rating,
        timeToNextIteration: ratingInterval,
      };
    case 'scales':
      return { getPreviewResultsIteration: scales };
    case 'wordcloud':
      return { getPreviewResultsIteration: wordcloud };
    default:
      return { getPreviewResultsIteration: null };
  }
};
