import * as React from 'react';
import { FILL } from '../utils';

export interface ParticlesT {
  config: any;
}

class Particles extends React.Component<ParticlesT> {
  id = '';

  constructor(props: ParticlesT) {
    super(props);
    this.id = this.generateRandomId();
  }

  override componentDidMount() {
    // Vendor adds the particle system to window object
    require('particles.js');
    this.createParticles(this.props);
  }

  override shouldComponentUpdate(nextProps: ParticlesT) {
    const { config } = this.props;
    const { config: nextConfig } = nextProps;
    if (config === nextConfig) return false;
    return true;
  }

  override componentDidUpdate() {
    this.destroyParticles();
    this.createParticles(this.props);
  }

  UNSAFE_componentWillUnmount() {
    this.destroyParticles();
  }

  createParticles = (configObj: any) => {
    if (
      Object.prototype.hasOwnProperty.call(configObj, 'config') &&
      window !== undefined
    ) {
      (window as any).particlesJS(`particles-js-${this.id}`, configObj.config);
    } else {
      // eslint-disable-next-line no-console
      console.error('No config was provided to Particles...');
    }
  };

  destroyParticles = () => {
    // Destroy
    try {
      (window as any).pJSDom[0].pJS.fn.vendors.destroypJS();
      (window as any).pJSDom = [];
    } catch (_e) {
      // eslint-disable-next-line no-console
      console.error('Could not destroy particleJS!');
    }
  };

  generateRandomId = () => {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(2, 10);
  };

  override render() {
    return (
      <div
        id={`particles-js-${this.id}`}
        style={{ ...FILL, userSelect: 'none' } as React.CSSProperties}
      />
    );
  }
}

export default Particles;
