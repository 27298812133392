import * as React from 'react';
import map from './map';

const context = React.createContext<
  typeof map.default & { Background?: React.ComponentType }
>(map.default);

export const ThemeComponentProvider = context.Provider;

export const useOptionalThemeComponent = () =>
  React.useContext(context) ?? map.default;
export const useThemeComponent = () => {
  const data = useOptionalThemeComponent();
  if (!data) {
    throw new Error(
      'useThemeComponent must be used within a ThemeComponentProvider',
    );
  }
  return data;
};
