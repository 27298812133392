import * as React from 'react';

export interface SfinxPresentationState {
  showTrends: boolean;
}

const context = React.createContext<SfinxPresentationState | undefined>(
  undefined,
);

export const PresentationProvider = context.Provider;
context.displayName = 'PresentationContext';

export const usePresentation = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('SfinxError: no presentation data found');
  }
  return data;
};
