import type {
  PreviewResultsInputs,
  RatingT,
} from '@mentimeter/question-modules-types';

const INITIAL_RESULTS: RatingT = {
  respondents: 0,
  type: 'rating',
  results: [],
  isPlaceholderResults: false,
  timestamp: 0,
  votes: {},
};

const resultsToVotes = (results: RatingT['results']): RatingT['votes'] =>
  results.reduce(
    (votes, result) => ({ ...votes, [result.id]: [result.score] }),
    {},
  );

export const previewResultsInterval = () => 1000;

export const getPreviewResultsIteration = ({
  previous = INITIAL_RESULTS,
  iteration,
  extras: { choices, range },
}: PreviewResultsInputs<RatingT>): RatingT => {
  const fullRange = range.max - range.min;
  if (iteration === 0 || previous.results.length === 0) {
    return {
      ...previous,
      respondents: 0,
      results: choices.map(({ id, label }) => ({
        id,
        label,
        score: [
          Math.floor(Math.random() * fullRange + range.min) + 1,
          Math.floor(Math.random() * fullRange + range.min) + 1,
        ],
      })),
    };
  }
  const newResults = previous.results.map(({ id, label, score }) => {
    const [prevX, prevY] = score;

    const deltaY = (Math.random() - 0.5) * (fullRange / 4);
    const deltaX = (Math.random() - 0.5) * (fullRange / 4);

    const newX = Math.min(
      Math.max((prevX ?? 0) + deltaX, range.min),
      range.max,
    );
    const newY = Math.min(
      Math.max((prevY ?? 0) + deltaY, range.min),
      range.max,
    );

    return {
      id,
      label,
      score: [newX, newY] as [number, number],
    };
  });

  return {
    ...previous,
    respondents: previous.respondents + Math.floor(Math.random() * 3) + 1,
    results: newResults,
    votes: resultsToVotes(newResults),
  };
};
