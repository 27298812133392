import type {
  ChoicesT,
  PreviewResultsInputs,
} from '@mentimeter/question-modules-types';
import type { SegmentT } from '@mentimeter/http-clients';

const defaultSegments: SegmentT[] = [{ id: null, label: '', position: -1 }];

const INITIAL_RESULTS: ChoicesT = {
  respondents: 0,
  type: 'choices',
  results: [],
  isPlaceholderResults: false,
  timestamp: 0,
  segments: defaultSegments,
};

export const getPreviewResultsIteration = ({
  previous = INITIAL_RESULTS,
  iteration,
  extras: { choices, segments },
}: PreviewResultsInputs<ChoicesT>): ChoicesT => {
  const output = { ...previous };

  const segmentsWithFallback = segments ?? defaultSegments;

  if (iteration === 0 || previous.results.length === 0) {
    output.respondents = 0;
    output.results = choices.map(({ id, label }) => ({
      id,
      label,
      score: segmentsWithFallback.map(() => 0),
    }));
  } else {
    output.results = previous.results.map(
      ({ id, label, score: prevScore }) => ({
        id,
        label,
        score: segmentsWithFallback.map((_, j) => {
          const maxRespondents = segmentsWithFallback.length > 1 ? 1 : 3;
          const numRespondents = Math.floor(Math.random() * maxRespondents) + 1;
          const changeSegment = Math.round(Math.random()) > 0;
          const score = prevScore[j] || 0;
          if (changeSegment) {
            output.respondents += numRespondents;
            return score + numRespondents;
          }
          return score;
        }),
      }),
    );
  }
  return output;
};
