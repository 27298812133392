import * as React from 'react';
import { Box, ImageProgressive } from '@mentimeter/ragnar-ui';
import { animated, type SpringValues, useTransition } from 'react-spring';
import type { Question } from '@mentimeter/http-clients';
import { useConfig, useQuestion } from '../../data-mediation';
import { useShowMedia } from './state';

export interface MediaOverlayT extends Pick<Question, 'cropped_image_url'> {
  enabled: boolean;
  showHeader: boolean;
}

const C = React.memo<MediaOverlayT>(
  ({ enabled, showHeader, cropped_image_url }: MediaOverlayT) => {
    const transitions = useTransition(enabled, {
      initial: null,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    });

    return transitions(
      (styles, item) =>
        item && (
          <Box
            key="media-overlay"
            id="media-overlay"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
            as={animated.div}
            px={4}
            pb={4}
            pt={showHeader ? '90px' : undefined}
            style={styles as SpringValues}
            extend={({ theme }) => ({ backgroundColor: theme.colors.bg })}
          >
            <Box position="relative" flex="1 1 auto" mb={5} width="100%">
              <Box
                position="absolute"
                top={0}
                right={0}
                height="100%"
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <ImageProgressive
                  src={cropped_image_url ? cropped_image_url : '#'}
                  alt="Image"
                />
              </Box>
            </Box>
          </Box>
        ),
    );
  },
);

export const MediaOverlay = () => {
  const { cropped_image_url, hide_voting_instructions, type } = useQuestion();
  const { mode } = useConfig();
  const [showMedia] = useShowMedia();
  const enabled =
    Boolean(cropped_image_url) &&
    showMedia &&
    type !== 'pin_on_image' &&
    type !== 'slide';

  if (!cropped_image_url || mode === 'screenshot') return null;

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      id="media-overlay-container"
      extend={() => ({
        pointerEvents: enabled ? undefined : 'none',
      })}
    >
      <C
        cropped_image_url={cropped_image_url}
        enabled={enabled}
        showHeader={!hide_voting_instructions}
      />
    </Box>
  );
};
