import type { CSSProperties } from 'react';

const STATIC_ASSET_HOST = 'https://static.mentimeter.com';
export const createStaticUrl = (relativeUrl: string): string => {
  return `${STATIC_ASSET_HOST}/${relativeUrl}`;
};

export const FILL: CSSProperties = {
  zIndex: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};
