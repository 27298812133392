const specialCharsRegex = /[.,/@#!$%^&*;:[\]|'"{}+=\-_`~()<>]/g;

const containsProfanity = (profanity: string, input: string): boolean => {
  // 1. Avoid computations if there are no matches (e.g., "clean words" does not contain "badWord")
  if (!input.includes(profanity)) {
    return false;
  }

  // 2. Check if exact match (e.g., "badWord" matches "badWord")
  if (profanity === input) {
    return true;
  }

  // 3. Check if match without punctuation (e.g., "badWord!!" matches "badWord")
  const inputWithoutPunctuation = input.replace(specialCharsRegex, '');
  if (inputWithoutPunctuation === profanity) {
    return true;
  }

  // 4. Check if array of individual words contains profanity (e.g., ['hej', 'badWord'] contains "badWord")
  const wordsArray = inputWithoutPunctuation.split(' ');
  if (wordsArray.includes(profanity)) {
    return true;
  }

  // 5. Check if an input contains a multi-word profanity (e.g., "bad words" or "bad words hej" contains "bad words")
  // NB: doing this simply using source.includes(badWord) will catch unwanted cases like "classic".includes("ass")
  wordsArray.forEach((w, i) => {
    if (!profanity.includes(w)) {
      wordsArray.splice(i, 1);
    }
  });
  if (wordsArray.join(' ') === profanity) {
    return true;
  }

  return false;
};

export const BAD_WORD_REPLACEMENT = '!#*&!$';

const profanityFilter = (source: string, profanities: string[]): string => {
  const lowerCaseWord = source.toLowerCase();
  const filteredSource =
    profanities.findIndex((profanity) => {
      const lowerCaseProfanity = profanity.toLowerCase();
      return containsProfanity(lowerCaseProfanity, lowerCaseWord);
    }) > -1
      ? BAD_WORD_REPLACEMENT
      : source;
  return filteredSource;
};

export default profanityFilter;
