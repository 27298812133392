import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import renderLog from '../utils/renderCount';
import Logo from '../misc/Logo';
import { MediaOverlay } from '../misc/media-overlay/MediaOverlay';
import { useQuestion } from '../data-mediation';
import { Background } from './Background';

const Content = React.memo(({ children }: React.PropsWithChildren) => {
  renderLog('Content');
  const question = useQuestion();

  return (
    <Box
      flex="1 1 auto"
      height="100%"
      width="100%"
      flexDirection="row"
      alignItems="center"
      extend={({ theme }) => ({
        // Fix for SVG font within this component
        '& svg *': {
          fontFamily: theme.fonts.body,
        },
        '& *': {
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
        },
        '& *::-webkit-scrollbar': { display: 'none' },
      })}
    >
      <Logo />
      <Background backgroundItem={question}>{children}</Background>
      <MediaOverlay />
    </Box>
  );
});

export default Content;
