import * as React from 'react';
import type { Series } from '@mentimeter/http-clients';

export interface SfinxSeriesState {
  id: string;
  closed_for_voting: boolean;
  vote_key: string;
  comments_enabled: boolean;
  qfa_active: boolean;
  qfa_intercom_enabled: boolean;
  qfa_moderation_enabled: boolean;
  reactions: Series['reactions'];
  participation_identity_mode: Series['participation_identity_mode'];
}

const context = React.createContext<SfinxSeriesState | undefined>(undefined);

export const SeriesProvider = context.Provider;
context.displayName = 'SeriesContext';

export const useSeries: () => SfinxSeriesState = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('SfinxError: no series data found');
  }
  return data;
};
