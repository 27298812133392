const TIMEOUT_MS = 25000;
const CHECK_INTERVAL_MS = 50;
const MAX_RETRIES = TIMEOUT_MS / CHECK_INTERVAL_MS;

let screenshotTaken = false;

const takeShot = () => {
  if (screenshotTaken) return;
  screenshotTaken = true;
  (window as any).callPhantom?.('takeShot');
};

const allImagesLoaded = () =>
  Array.from(document.images).every((img) => img.complete);
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const waitForImages = async () => {
  for (let retries = 0; retries < MAX_RETRIES; retries++) {
    if (allImagesLoaded()) return;
    await wait(CHECK_INTERVAL_MS);
  }
};

export const takeScreenshot = () => waitForImages().then(takeShot);
