import * as React from 'react';
import { createStaticUrl } from '../utils';
import Parallax from '../components/Parallax';

// Change to static later!
const layer1 = createStaticUrl(`static/themes/explore/background.jpg`);
const layer2 = createStaticUrl(`static/themes/explore/layer-2.png`);
const layer3 = createStaticUrl(`static/themes/explore/layer-3.png`);
const layer4 = createStaticUrl(`static/themes/explore/layer-4.png`);
const layer5 = createStaticUrl(`static/themes/explore/layer-5.png`);

const LAYERS = [layer1, layer2, layer3, layer4, layer5];

const Explore = ({ progress }: { progress: number }) => {
  return <Parallax imageUrls={LAYERS} progress={progress} />;
};

export default Explore;
