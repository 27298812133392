import * as React from 'react';
import { Box, ThemeBgSensitive } from '@mentimeter/ragnar-ui';
import { useQuestion, useTheme } from '../../data-mediation';

export const Indications = ({ children }: React.PropsWithChildren) => {
  const { background_color } = useTheme();
  const { theme_settings } = useQuestion();
  const themeBg = theme_settings?.background_color ?? background_color;
  return (
    <Box
      width="100%"
      zIndex={2}
      left={0}
      bottom={0}
      position="absolute"
      p="space6"
      flexDirection="row"
      justifyContent="flex-end"
      gap="space3"
    >
      <ThemeBgSensitive themeBg={themeBg}>{children}</ThemeBgSensitive>
    </Box>
  );
};
