import * as React from 'react';
import {
  addBreadcrumb,
  captureException,
  MentiError,
} from '@mentimeter/errors/sentry';
import type { DispatchT } from '../../data-mediation/types/flux';

const defaultState: DispatchT = (action) => {
  addBreadcrumb({
    message: 'Tried to dispatch without provider',
    data: {
      action,
    },
  });
  captureException(
    new MentiError('Tried to dispatch without provider', {
      feature: 'live',
    }),
  );
};

const context = React.createContext<DispatchT>(defaultState);
context.displayName = 'DispatchContext';

export const DispatchProvider = context.Provider;

/**
 * Calling dispatches within sfinx in readonly views such as thumbnails will throw errors.
 * Avoid calling dispatch on render with useEffect.
 */
export const useDispatch = () => React.useContext(context);
