import { Box } from '@mentimeter/ragnar-ui';
import { useQuestion } from '../data-mediation';
import { SideBySideImage } from './SideBySideImage';

export const SlideWrapper = ({ children }: React.PropsWithChildren) => {
  const { type, cropped_image_url, image_alt_description, layout } =
    useQuestion();

  return (
    <Box width="100%" height="100%" py="112px" px="space16">
      <SideBySideImage
        imageUrl={cropped_image_url}
        imageAltText={image_alt_description}
        layoutType={layout?.type}
        imageWidth={type === 'slide' ? '50%' : '35%'}
      >
        {children}
      </SideBySideImage>
    </Box>
  );
};
