import { type PropsWithChildren } from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui';
import { Box } from '@mentimeter/ragnar-ui';
import type { DSC } from '@mentimeter/ragnar-dsc';
import { alpha } from '@mentimeter/ragnar-colors';
import type { QuestionWithSlide } from '@mentimeter/editor-schema/api-types-overrides';
import { CanvasPlaceholder } from '../misc/assets/CanvasPlaceholder';

const Placeholder = () => (
  <Box
    height="100%"
    width="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    data-testid="content-image-placeholder"
  >
    <CanvasPlaceholder width="40%" height="40%" />
  </Box>
);

const Wrap = ({ children }: { children: React.ReactNode }) => (
  <Box flex="1 1 auto" width="100%" height="100%">
    {children}
  </Box>
);

const FullSizeBackground = ({
  backgroundImage,
  backgroundImageSize,
  shouldShowColorOverlay,
  enableAnimations,
  ...props
}: BoxT & {
  backgroundImage?: string | undefined;
  backgroundImageSize: string | 'cover' | 'contain';
  enableAnimations?: boolean | undefined;
  shouldShowColorOverlay?: boolean | undefined;
}) => {
  return (
    <Box
      width="100%"
      height="100%"
      data-testid="content-image-full-size"
      extend={({ theme }) => ({
        backgroundImage: shouldShowColorOverlay
          ? `linear-gradient(
              ${alpha(theme.colors.bg, theme.backdropAlpha ?? 1)},
              ${alpha(theme.colors.bg, theme.backdropAlpha ?? 1)}
              ), url(${backgroundImage})`
          : `url(${backgroundImage || ''})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: backgroundImageSize || 'cover',
      })}
      {...props}
    />
  );
};

type BackgroundItem = Pick<
  QuestionWithSlide,
  | 'type'
  | 'slide_type'
  | 'image'
  | 'layout'
  | 'slide_image_type'
  | 'image_as_background'
>;

const extend =
  (contentImage: string | undefined) =>
  ({ theme }: { theme: DSC }) => ({
    backgroundColor: contentImage
      ? 'transparent'
      : alpha(theme.colors.neutralWeak, 0.5),
    backgroundImage: `url(${contentImage || ''})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  });

export const Background = ({
  children,
  backgroundItem,
}: PropsWithChildren<{ backgroundItem: BackgroundItem }>) => {
  const {
    type,
    slide_type,
    image,
    layout,
    slide_image_type,
    image_as_background,
  } = backgroundItem;
  const contentImage =
    image?.presets?.original?.url ?? image?.presets?.large?.url;

  const isDocumentSlide = type === 'slide' && slide_type === 'document';
  const isFullSizeImageSlide =
    type === 'slide' &&
    slide_type === 'image' &&
    (image_as_background || slide_image_type === 'image-background');

  if (isDocumentSlide || isFullSizeImageSlide) {
    return (
      <FullSizeBackground
        backgroundImage={contentImage}
        backgroundImageSize={isDocumentSlide ? 'contain' : 'cover'}
      >
        {children}
      </FullSizeBackground>
    );
  }

  if (layout?.type === 'full-bg') {
    return (
      <FullSizeBackground
        backgroundImage={contentImage}
        backgroundImageSize="cover"
        shouldShowColorOverlay
      >
        {children}
      </FullSizeBackground>
    );
  }

  const isVerticalImage = ['left', 'right'].includes(layout?.type || '');
  const isHorizontalImage = ['top', 'bottom'].includes(layout?.type || '');

  if (isHorizontalImage) {
    return (
      <Box
        width="100%"
        height="100%"
        flexDirection={layout?.type === 'top' ? 'column' : 'column-reverse'}
      >
        <Box
          width="100%"
          height={layout?.size}
          extend={extend(contentImage || '')}
          data-testid="content-image-horizontal"
        >
          {!contentImage && <Placeholder />}
        </Box>
        {/* Children needs to be rendered after the image in order to be able to be displayed
        on top of it, using zindex. (needed for open ended screenshots) */}
        <Wrap>{children}</Wrap>
      </Box>
    );
  }

  if (isVerticalImage) {
    return (
      <Box
        width="100%"
        height="100%"
        flexDirection={layout?.type === 'right' ? 'row-reverse' : 'row'}
      >
        <Box
          width={layout?.size}
          height="100%"
          data-testid="content-image-vertical"
          extend={extend(contentImage || '')}
        >
          {!contentImage && <Placeholder />}
        </Box>
        {/* Children needs to be rendered after the image in order to be able to be displayed
        on top of it, using zindex. (needed for open ended screenshots) */}
        <Wrap>{children}</Wrap>
      </Box>
    );
  }

  return children;
};
