import { useEffect, useState, useRef, useCallback } from 'react';

export const useDelayedState = <T>(
  initialState: T,
): [T, (value: T, delay?: number) => void] => {
  const [state, setState] = useState<T>(initialState);
  const ref = useRef<NodeJS.Timeout | null>(null);

  const setStateDelayed = useCallback((value: T, delay = 0) => {
    if (ref.current !== null) {
      clearTimeout(ref.current);
    }
    if (delay) {
      ref.current = setTimeout(() => setState(value), delay);
    } else {
      setState(value);
    }
  }, []);

  // Clear timeout when component is unmounted
  useEffect(
    () => () => {
      if (ref.current !== null) {
        clearTimeout(ref.current);
      }
    },
    [],
  );

  return [state, setStateDelayed];
};
