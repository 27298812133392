import * as DefaultPrimitives from './default';
import * as MentiBrand from './menti-brand';
import * as City from './city';
import * as Greenhouse from './greenhouse';
import * as Nature from './nature';
import * as Space from './space';
import * as Gradient from './gradient';
import * as ColorJoy from './colorjoy';
import * as Waves from './waves';
import * as WavesBlue from './waves-blue';
import * as WavesPurple from './waves-purple';
import * as Explore from './explore';
import * as NeuralNetworks from './neural-networks';
import * as Spark44 from './spark44';

const MAP = {
  default: DefaultPrimitives,
  'menti-brand': { ...DefaultPrimitives, ...MentiBrand },
  'business-light': { ...DefaultPrimitives },
  'business-dark': { ...DefaultPrimitives },
  city: { ...DefaultPrimitives, ...City },
  monochrome: { ...DefaultPrimitives },
  greenhouse: { ...DefaultPrimitives, ...Greenhouse },
  nature: { ...DefaultPrimitives, ...Nature },
  gradient: { ...DefaultPrimitives, ...Gradient },
  space: { ...DefaultPrimitives, ...Space },
  barbershop: { ...DefaultPrimitives },
  colorjoy: { ...DefaultPrimitives, ...ColorJoy },
  waves: { ...DefaultPrimitives, ...Waves },
  'waves-blue': { ...DefaultPrimitives, ...WavesBlue },
  'waves-purple': { ...DefaultPrimitives, ...WavesPurple },
  explore: { ...DefaultPrimitives, ...Explore },
  spark44: { ...DefaultPrimitives, ...Spark44 },
  'particle-blue': { ...DefaultPrimitives, ...NeuralNetworks },
};

export default MAP;
