import * as React from 'react';
import { Box, Text } from '@mentimeter/ragnar-ui';

export const IndicationNumber = ({ number = 0 }: { number: number }) => (
  <Box
    position="absolute"
    left="50%"
    justifyContent="center"
    alignItems="center"
    bg="primary"
    borderRadius="full"
    p="space1"
    extend={({ theme }) => ({
      opacity: number > 0 ? 1 : 0,
      minWidth: `${theme.fontSizes[3]}px`,
      transform: 'translateX(-50%) translateY(-125%)',
      transition: `opacity ${theme.durations[1]}s ease`,
    })}
  >
    <Text lineHeight="none" fontWeight="bold" color="onPrimary" fontSize={1}>
      {number}
    </Text>
  </Box>
);
