import * as React from 'react';
import type {
  QuizPlayersScoreData,
  QuizResultData,
} from '@mentimeter/core-hooks';
import type { QuizModuleResult, QuizResultWithPreview } from '.';

export const QuizResultsContext = React.createContext<
  QuizModuleResult | undefined
>(undefined);
QuizResultsContext.displayName = 'QuizResultContext';

export const useQuizResults = <
  T extends QuizPlayersScoreData | QuizResultData,
>() => {
  const context = React.useContext(QuizResultsContext);

  if (!context)
    throw new Error(
      'useQuizResults must be used within a QuizResultsContext.Provider',
    );

  return context as T & QuizResultWithPreview;
};
