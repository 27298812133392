import * as React from 'react';
import { createComponent } from '@mentimeter/ragnar-react';
import type { CSSProperties } from 'react';
import type { IRenderer, IStyle } from 'fela';

export interface AnimationControlT {
  duration?: number;
  delay?: number;
  easing?: string;
}

export const create = (
  css: CSSProperties,
  keyframes: Record<string, IStyle | undefined>,
) => {
  const animation = () => keyframes;
  const animationRule = (
    { duration = 80000, delay = 0, easing = 'ease-in-out' }: AnimationControlT,
    renderer: IRenderer,
  ) => ({
    ...css,
    animation: `${renderer.renderKeyframe(
      animation,
      {},
    )} ${duration}ms ${easing} ${delay}ms infinite`,
  });
  return createComponent(
    animationRule,
    'div',
  ) as React.ComponentType<ContinuousComponentT>;
};

export interface ContinuousComponentT extends AnimationControlT {
  style?: React.CSSProperties;
}

export interface ContinuousItemT {
  id: string;
  component: React.ComponentType<ContinuousComponentT>;
  props?: ContinuousComponentT;
}

export interface ContinuousT {
  components: Array<ContinuousItemT>;
}

const Continuous = ({ components }: ContinuousT) => {
  return (
    <>
      {components.map(({ component: C, props, id }) => (
        <C key={id} {...props} />
      ))}
    </>
  );
};

export default Continuous;
