import type {
  PinOnImageT,
  PreviewResultsInputs,
} from '@mentimeter/question-modules-types';

const INITIAL_RESULTS: PinOnImageT = {
  respondents: 0,
  type: 'pin_on_image',
  results: [],
  isPlaceholderResults: false,
  timestamp: 0,
  segments: [],
};

const isWithinCorrectArea = (
  percentage_x: number,
  percentage_y: number,
  correctArea: number[][] | null,
) => {
  if (correctArea === null) {
    return true;
  }

  const x_min = correctArea[0]?.[0] ?? 0;
  const x_max = correctArea[2]?.[0] ?? 0;
  const y_min = correctArea[0]?.[1] ?? 0;
  const y_max = correctArea[2]?.[1] ?? 0;

  return (
    percentage_x > x_min &&
    percentage_x < x_max &&
    percentage_y > y_min &&
    percentage_y < y_max
  );
};

export const previewResultsInterval = () => Math.random() * 1000 + 500;

export const getPreviewResultsIteration = ({
  previous = INITIAL_RESULTS,
  iteration,
  extras: { correctArea },
}: PreviewResultsInputs<PinOnImageT>): PinOnImageT => {
  if (iteration === 0) {
    return { ...previous };
  }
  const minPins = 1;
  const maxPins = 5;

  const percentage_x = Math.random();
  const percentage_y = Math.random();
  const number_of_pins = Math.floor(Math.random() * maxPins) + minPins;

  const is_correct = isWithinCorrectArea(
    percentage_x,
    percentage_y,
    correctArea,
  );

  const newResult = { percentage_x, percentage_y, number_of_pins, is_correct };

  return {
    ...previous,
    results: [...previous.results, newResult],
    respondents: previous.respondents + newResult.number_of_pins,
  };
};
