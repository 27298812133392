import type { Question, SegmentT } from '@mentimeter/http-clients';
import type { ChoicesResultT } from '@mentimeter/visualizations';

export function addUnknownSegmentIfNeeded({
  segments,
  results,
  segmentedBy,
}: {
  results: ChoicesResultT[];
  segments: SegmentT[];
  segmentedBy?: Question;
}): SegmentT[] {
  const choices = segmentedBy?.choices ?? [];
  if (
    choices.length > 0 &&
    results.some((res) => (res.score[choices.length] ?? 0) > 0)
  ) {
    return [
      ...choices,
      { id: null, label: 'Unknown', position: segments.length },
    ];
  }
  return choices;
}
