import * as React from 'react';

export const HasQuizResultsContext = React.createContext<boolean | undefined>(
  undefined,
);
HasQuizResultsContext.displayName = 'HasQuizResultsContext';

export const useHasQuizResults = () => {
  const context = React.useContext(HasQuizResultsContext);

  if (context === undefined)
    throw new Error(
      'useHasQuizResults must be used within a HasQuizResultsContext.Provider',
    );

  return context;
};
