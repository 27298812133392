import * as React from 'react';

export interface ModerationState {
  restoreAllWords: () => void;
  toggleWord: (answer: string) => void;
  moderationEnabled: boolean;
  removedWords: string[];
}

const context = React.createContext<ModerationState | undefined>(undefined);

context.displayName = 'ModerationContext';

export const ModerationProvider = context.Provider;

const throwNoModerationDataException = () => {
  throw new Error('SfinxError: no moderation data found');
};

export const useModeration = () => {
  const data = React.useContext(context);
  if (!data) {
    return {
      restoreAllWords: throwNoModerationDataException,
      toggleWord: throwNoModerationDataException,
      removedWords: [],
      moderationEnabled: false,
    };
  }

  const { removedWords, toggleWord, restoreAllWords, moderationEnabled } = data;

  return {
    restoreAllWords,
    toggleWord,
    removedWords,
    moderationEnabled,
  };
};
