import * as React from 'react';
import { Box, useImageLoader } from '@mentimeter/ragnar-ui';
import { useConfig, useTheme } from '../data-mediation';
import { Stagger } from '../slide-layout/Stagger';
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../utils/constants';

export interface LogoCT {
  enableAnimations: boolean;
  logoUrl: string;
}

const WIDTH_FACTOR = 1 / 12;
const HEIGHT_FACTOR = 1 / 12;

export const LogoSpacer = () => {
  return <Box display="block" width={CANVAS_WIDTH * WIDTH_FACTOR} />;
};

const C = React.memo<LogoCT>(({ logoUrl, enableAnimations }: LogoCT) => {
  const hasLoaded = useImageLoader(logoUrl);

  if (!hasLoaded) return null;

  return (
    <Box
      display="block"
      width={CANVAS_WIDTH * WIDTH_FACTOR}
      position="absolute"
      zIndex={2}
      top={0}
      right={0}
      extend={({ theme }) => ({
        transform: `translate(-${theme.space[4]}px, calc(${theme.space[4]}px))`,
        transition: `transform ${
          enableAnimations ? theme.durations[1] : 0
        }s ease`,
        pointerEvents: 'none', // logo can overlap visualization, ensures anything underneath it is clickable
      })}
    >
      <Stagger type="fade" index={0}>
        <Box
          key="logo"
          display="block"
          width={CANVAS_WIDTH * WIDTH_FACTOR}
          height={CANVAS_HEIGHT * HEIGHT_FACTOR}
          extend={() => ({
            backgroundImage: `url(${logoUrl})`,
            backgroundPosition: 'top right',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          })}
        />
      </Stagger>
    </Box>
  );
});

const Logo = () => {
  const { enableAnimations } = useConfig();
  const theme = useTheme();

  if (!theme?.logo_cropped_image_url) return null;

  return (
    <C
      enableAnimations={enableAnimations}
      logoUrl={theme.logo_cropped_image_url}
    />
  );
};

export default Logo;
