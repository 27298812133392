// Returns an index i ∈ [0, total]
export const toNonContinuous = (
  total: number,
  progress: number,
  index?: number,
): number => {
  return index !== undefined
    ? index % (total + 1)
    : Math.floor(progress * total);
};

// Returns a progress p ∈ [0,1]
export const toContinuous = (
  total: number,
  index: number,
  progress?: number,
): number => {
  return progress !== undefined ? progress : (index % (total + 1)) / total;
};
