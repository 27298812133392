/* eslint-disable menti-react/filename-convention--jsx */
import type { ComponentsT } from '@mentimeter/string-parser';
import { StringParser as SP } from '@mentimeter/string-parser';
import { Text, type TextT } from '@mentimeter/ragnar-ui';
import {
  defaultPropsBold,
  stringParserComponents as spc,
} from './StringParser';

export const Bold = (props: TextT) => (
  <Text
    {...defaultPropsBold}
    fontWeight="semiBold"
    lineHeight="inherit"
    color="primary"
    fontSize="inherit"
    {...props}
  />
);

export const stringParserComponents: ComponentsT = {
  ...spc,
  Strong: Bold,
};

export const StringParser = ({
  source,
  disableLineBreaks,
  allowBlankLines = false,
}: {
  source: string;
  disableLineBreaks: boolean;
  allowBlankLines?: boolean;
}) => (
  <SP
    components={stringParserComponents}
    source={source}
    disableLineBreaks={disableLineBreaks}
    allowBlankLines={allowBlankLines}
  />
);
