import type { Layout, Question } from '@mentimeter/http-clients';
import type {
  QuestionMediaT,
  RegisteredHotkeysT,
  ContentSDKT,
} from '@mentimeter/question-modules-types';

export const HOTKEY_DEBUG = {
  logNativeEvent: true,
  logHotkeysClass: true,
  logHotkeyId: true,
};

export const KEYSTROKES: Array<RegisteredHotkeysT> = [
  'arrowleft',
  'arrowup',
  'arrowdown',
  'arrowright',
  'enter',
  'escape',
  'space',
  's',
];

export { getFixedNumberOfDefaultChoices } from './content/getFixedNumberOfDefaultChoices';
export { removeChoice, updateChoiceImage } from './content/choice-utils';

// To be used on those slides that are not using the image uploader from editor-boundary yet
export const handleContentImageUploadSuccess =
  (setQuestion: ReturnType<ContentSDKT['useQuestion']>[1]) =>
  (mediaData: Partial<QuestionMediaT>) => {
    const getUpdatedLayoutType = (
      currentLayoutType: Layout['type'] | undefined,
    ): Layout['type'] => {
      const isUpload = Boolean(mediaData.question_image_path);
      if (isUpload) return 'right';
      // Here, is important to explicitly check for null, as undefined would mean edit not delete
      const isDelete = mediaData.question_image_path === null;
      if (isDelete) return 'default';
      return currentLayoutType || 'right';
    };

    const getLayoutHash = (
      d: Partial<Question>,
      updatedLayoutType: Layout['type'],
    ): Layout => {
      return {
        font_size_offset: 0,
        alignment_x: 1,
        alignment_y: 1,
        size: '33%',
        ...(d.layout || {}),
        type: updatedLayoutType,
      };
    };
    setQuestion((d) => ({
      ...d,
      ...mediaData,
      layout: { ...getLayoutHash(d, getUpdatedLayoutType(d?.layout?.type)) },
    }));
  };
