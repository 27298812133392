import * as React from 'react';
import type { PresentationStore, State } from '@mentimeter/presentation-state';

type PaceActionsT = Omit<PresentationStore['actions'], 'initiate'>;

export interface SfinxPaceState {
  state?: State | undefined;
  selectors?: PresentationStore['selectors'];
  actions: PaceActionsT;
}

const context = React.createContext<SfinxPaceState | undefined>(undefined);
context.displayName = 'PaceContext';

/**
  A hook that returns the current pace state if it exists, otherwise it returns undefined.
  Make sure to create a fallback data if you use this hook, as the pace state will be undefined
  in e.g. Thumbnails
*/
export const useOptionalPace = () => {
  return React.useContext(context);
};

export const usePace = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('SfinxError: no pace data found');
  }
  return data;
};

export const PaceProvider = context.Provider;
