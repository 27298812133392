import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { VoteIndicator as VoteIndicatorIcon } from '@mentimeter/ragnar-visuals';
import { useQuestionResults } from '../../data-mediation/question-results';
import { getCategory } from '../../utils/category';
import { useQuestion } from '../../data-mediation';
import { IndicationNumber } from './IndicationNumbers';
import { Indication } from './Indication';

interface VoteIndicatorT {
  respondents: number;
}

const C = React.memo<VoteIndicatorT>(({ respondents }: VoteIndicatorT) => {
  return (
    <Box data-testid="vote-indicator">
      <Indication>
        <IndicationNumber number={respondents} />
        <VoteIndicatorIcon size={3} color="text" />
      </Indication>
    </Box>
  );
});

export const VoteIndicator = () => {
  const { respondents = 0 } = useQuestionResults() ?? {};
  const { type } = useQuestion();
  const category = getCategory(type);

  if (category !== 'interactive') return null;

  return <C respondents={respondents} />;
};
