import type {
  OpenT,
  PreviewResultsInputs,
} from '@mentimeter/question-modules-types';

const OPEN_ENDED_TEXTS = [
  'Be yourself; everyone else is already taken.',
  'No one can make you feel inferior without your consent.',
  'The greatest thing you will ever learn is just to love and be loved in return.',
  'Do, or do not. There is no "try".',
  'Be the change that you wish to see in the world.',
  'I have seen the best of you, and the worst of you, and I choose both.',
  "What defines you isn't how many times you crash but the number of times you get back.",
  'Life isn’t about getting and having, it’s about giving and being.',
  'You miss 100% of the shots you don’t take.',
  'I attribute my success to this: I never gave or took any excuse.',
  'Life is what happens to you while you’re busy making other plans.',
  'The best time to plant a tree was 20 years ago. The second best time is now.',
  'Eighty percent of success is showing up.',
  'The two most important days in your life are the day you are born and the day you find out why.',
  'Life shrinks or expands in proportion to one’s courage.',
  'Whether you think you can or you think you can’t, you’re right.',
];

const INITIAL_RESULTS: OpenT = {
  respondents: 0,
  type: 'open',
  results: [],
  isPlaceholderResults: false,
  timestamp: 0,
  votes: [],
  segments: [],
  voter_count: 0,
};

export const getPreviewResultsIteration = ({
  previous = INITIAL_RESULTS,
  iteration,
}: PreviewResultsInputs<OpenT>): OpenT => {
  const output = { ...previous };

  if (iteration === 0) {
    return output;
  }

  const testResult = OPEN_ENDED_TEXTS[iteration - 1]!;

  const testVote = {
    answer: OPEN_ENDED_TEXTS[(iteration - 1) % OPEN_ENDED_TEXTS.length]!,
    id: `test-${iteration}`,
    upvotes: (iteration * 3) % 4,
  };

  if (testVote) {
    output.results = [...previous.results, testResult];
    output.votes = [...previous.votes, testVote];
    output.respondents += 1;
  }

  return output;
};
