import * as React from 'react';
import { ThemeProvider as RagnarThemeProvider } from '@mentimeter/ragnar-react';
import type { UserThemeT } from '@mentimeter/sfinx-themes';
import { createUserTheme } from '@mentimeter/sfinx-themes';
import type { Question, Series } from '@mentimeter/http-clients';
import { ThemeComponentProvider } from '../misc/themes/theme-components';
import map from '../misc/themes/map';
import type { SfinxThemeState } from '../misc/themes/state/types';
import { themeOverride } from '../misc/themes/state/normalizers';
import { ThemeContextProvider } from '../misc/themes/state';
import { InternalFontScalingProvider } from '../canvas/config';

const mergePresentationAndSlideTheme = (
  theme: SfinxThemeState,
  slideTheme?: SfinxThemeState,
): UserThemeT => {
  const themeColors = {
    text_color: theme.text_color,
    line_color: theme.line_color,
    bar_color: theme.bar_color,
    background_color: theme.background_color,
  };

  const slideColors = {
    ...(slideTheme?.text_color && { text_color: slideTheme?.text_color }),
    ...(slideTheme?.line_color && { line_color: slideTheme?.line_color }),
    ...(slideTheme?.bar_color && { bar_color: slideTheme?.bar_color }),
    ...(slideTheme?.background_color && {
      background_color: slideTheme?.background_color,
    }),
  };

  return createUserTheme({
    userColors: slideTheme ? { ...themeColors, ...slideColors } : themeColors,
    font: theme.font,
    backdropAlpha: slideTheme?.backdrop_alpha ?? theme.backdrop_alpha,
  });
};

/** A wrapper component for Sfinx that allows you to provide a user-defined theme,
 * and then applies it as a ragnar theme to its children.
 * @param presentationTheme: either a user-defined theme, OR an enhanced theme as produced by mergeTheme in the Editor application
 * @param slideTheme an optional slide-specific user-defined theme.
 */
export const ThemeProvider = ({
  children,
  presentationTheme,
  slideTheme,
}: {
  children: React.ReactNode;
  presentationTheme: SfinxThemeState | Series['theme'];
  slideTheme?: Question['theme_settings'] | undefined;
}) => {
  const combinedTheme = React.useMemo(() => {
    const overridden = slideTheme ? themeOverride(slideTheme) : undefined;
    return mergePresentationAndSlideTheme(presentationTheme, overridden);
  }, [slideTheme, presentationTheme]);

  const { config_id } = presentationTheme;

  const components = config_id ? map[config_id] : map.default;

  return (
    <InternalFontScalingProvider>
      <ThemeComponentProvider value={components}>
        <ThemeContextProvider value={presentationTheme}>
          <RagnarThemeProvider theme={combinedTheme}>
            {children}
          </RagnarThemeProvider>
        </ThemeContextProvider>
      </ThemeComponentProvider>
    </InternalFontScalingProvider>
  );
};
