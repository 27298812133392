import type { QuestionType } from '@mentimeter/http-clients';

export const getCategory = (
  type: QuestionType | undefined,
): 'content' | 'interactive' | 'quiz' | 'qfa' | 'unknown' => {
  switch (type) {
    case 'slide':
      return 'content';
    case 'quiz':
    case 'quiz_open':
    case 'quiz_leaderboard':
      return 'quiz';
    case 'qfa':
      return 'qfa';
    default:
      return 'interactive';
  }
};
