import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import { toNonContinuous } from '../utils/continuous';

// Transition durations
const SLOW = {
  tension: 10,
  friction: 300,
};
const FAST = {
  tension: 200,
  friction: 100,
};

/*
 * Default images
 * src: image url
 * backgroundPosition: the background position (see CSS spec)
 * transformOrigin: The origin of the transform
 */
export interface KenBurnsImage {
  src: string;
  backgroundPosition: string;
  transformOrigin: string;
}

export interface AnimationWrapperProps extends KenBurnsImage {
  state: 'active' | 'inactive';
}

/*
 * Wrapper for both transitions as they are independent.
 */
const AnimationWrapper = ({
  state,
  src,
  transformOrigin,
  backgroundPosition = 'center',
}: AnimationWrapperProps) => {
  // Zoom transition (Ken Burns)
  const { transform } = useSpring({
    from: { transform: 1 },
    to: { transform: state === 'active' ? 1.2 : 1 },
    config: SLOW,
  });
  // Opacity transition
  const { opacity } = useSpring({
    opacity: state === 'active' ? 1 : 0,
    config: FAST,
  });

  return (
    <animated.div style={{ opacity }}>
      <animated.div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundSize: 'cover',
          transformOrigin,
          backgroundPosition,
          backgroundImage: `url(${src})`,
          width: '100%',
          height: '100%',
          transform: transform.to((t) => `scale(${t})`),
        }}
      />
    </animated.div>
  );
};

const KenBurns = ({
  progress,
  images,
  currentIndex,
}: {
  images: KenBurnsImage[];
  currentIndex: number;
  progress: number;
}) => {
  const index = toNonContinuous(images.length - 1, progress, currentIndex);
  return (
    <>
      {images.map((img, i) => (
        <AnimationWrapper
          key={img.src}
          {...img}
          state={i === index ? 'active' : 'inactive'}
        />
      ))}
    </>
  );
};

export default KenBurns;
