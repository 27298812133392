/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import type { Reaction, ReactionResult } from '@mentimeter/http-clients';
import {
  type ReactionQueueItem,
  type RealtimeReactionQueues,
  useRealtimeReactionsStore,
} from '@mentimeter/reactions-realtime';
import { useConfig, useQuestion, useSeries } from '../../../data-mediation';
import { useThemeComponent } from '../../../misc/themes/theme-components';
import { Indication } from '../Indication';
import { IndicationNumber } from '../IndicationNumbers';
import { useTestVotesState } from '../../../misc/presentation-actions/testVotes';
import { useReactionsResults } from '../../../misc/reactions-results';
import { usePreviewReactionsIterator } from './usePreviewReactionsIterator';
import Animation from './Animation';

interface Props {
  votes: number;
  reactionId: Reaction;
}

const ReactionItem = React.memo<Props>(({ reactionId, votes }) => {
  const { ReactionMap } = useThemeComponent();

  const Icon = ReactionMap[reactionId];
  if (!Icon) return null;

  return (
    <Indication>
      <IndicationNumber number={votes} />
      <Icon size={3} color="primary" />
    </Indication>
  );
});

interface ReactionTestVotes {
  data: RealtimeReactionQueues;
  results: ReactionResult;
}

const REACTION_MAX_DELAY = 250;
const REACTION_DURATION = 2000;

const useReactionTestVotes = (
  testVotesShowing: boolean,
  reactions: Reaction[],
): ReactionTestVotes => {
  const getNextIteration = React.useCallback(
    (iteration: number, previous: ReactionTestVotes) => {
      return reactions.reduce(
        (acc, reaction) => {
          // First iteration is 0
          const numNewReactions =
            iteration > 0
              ? Math.min(
                  Math.floor(Math.random() * 5),
                  Math.floor(Math.random() * 5),
                  Math.floor(Math.random() * 5),
                )
              : 0;
          // Increase result for this reaction
          const result = (acc.results[reaction] ?? 0) + numNewReactions;
          // Data is the realtime part
          const reactionData = {
            timestamp: Date.now(),
            amount: numNewReactions,
            colorIndex: 0,
          };
          const reactionQueueData: ReactionQueueItem[] = [];
          for (let index = 0; index < reactionData.amount; index++) {
            const delay = Math.floor(Math.random() * REACTION_MAX_DELAY);
            reactionQueueData.push({
              id: `${reaction}-${reactionData.timestamp}-${index}`,
              duration: REACTION_DURATION + delay,
              delay,
              colorIndex: reactionData.colorIndex,
              index,
            });
          }
          return {
            results: {
              ...acc.results,
              [reaction]: result,
            },
            data: {
              ...acc.data,
              [reaction]: reactionQueueData,
            },
          };
        },
        { results: previous.results, data: previous.data },
      );
    },
    [reactions],
  );

  return usePreviewReactionsIterator<ReactionTestVotes>(
    testVotesShowing,
    React.useMemo(() => ({ data: {}, results: {} }), []),
    getNextIteration,
  );
};

export const Reactions = () => {
  const { enableAnimations } = useConfig();
  const { reactions: seriesReactions } = useSeries();
  const { reactions: questionReactions } = useQuestion();
  const { testVotesShowing } = useTestVotesState();
  const data = useReactionsResults();
  const currentReactionData = useRealtimeReactionsStore(
    (state) => state.reactions,
  );

  const reactions = seriesReactions ?? questionReactions;

  const { results: testReactionResults, data: testReactionData } =
    useReactionTestVotes(
      testVotesShowing &&
        Boolean(questionReactions?.length && questionReactions?.length > 0),
      reactions || [],
    );
  const reactionResults = testVotesShowing ? testReactionResults : data;

  const reactionData = testVotesShowing
    ? testReactionData
    : currentReactionData;

  if (!reactions) {
    return null;
  }

  return (
    <Box flexDirection="row" gap="space3">
      {reactions.map((reactionId) => {
        const reactionQueue = reactionData?.[reactionId];
        const votes = reactionResults?.[reactionId] ?? 0;

        return (
          <Box
            key={reactionId}
            data-testid={`reaction-indicator-${reactionId}`}
          >
            <ReactionItem reactionId={reactionId} votes={votes} />
            {enableAnimations && reactionQueue && (
              <Animation
                reactionId={reactionId}
                reactionQueue={reactionQueue}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
