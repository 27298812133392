import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import type { BoxT } from '@mentimeter/ragnar-ui';

export const Indication = ({ children, ...rest }: BoxT) => (
  <Box
    borderRadius="full"
    p="space4"
    justifyContent="center"
    alignItems="center"
    extend={({ theme }) => ({
      backdropFilter: theme.kosmosBlur.xl,
      WebkitBackdropFilter: theme.kosmosBlur.xl,
      backgroundColor: theme.colors.surfaceOverlayTransparent,
      color: theme.colors.primary,
      '@media (hover: hover)': {
        ':hover': {
          backgroundColor: theme.colors.surfaceOverlayTransparentHover,
          '> div': {
            marginTop: '-5px',
          },
          '> svg': {
            transform: 'scale(1.15)',
          },
        },
      },
      '> div': {
        transition: `margin ${theme.durations[0]}s ease-in-out`,
      },
      '> svg': {
        transition: `transform ${theme.durations[0]}s ease-in-out`,
      },
    })}
  >
    {children}
  </Box>
);
