import * as React from 'react';
import { HotkeyProvider } from '@mentimeter/hotkeys';
import { type Question } from '@mentimeter/http-clients';
import { useSetControlContext } from '@mentimeter/question-modules-contexts';
import { importControlsDynamically } from '@mentimeter/question-modules-importer';
import { HOTKEY_DEBUG, KEYSTROKES } from '@mentimeter/question-modules-shared';
import type {
  EmptyT,
  QuestionModuleResult,
} from '@mentimeter/question-modules-types';
import type { SlideState } from '@mentimeter/presentation-state';
import { useHasQuizResults } from '@mentimeter/quiz';
import {
  usePace,
  useSessions,
  useTestVotesState,
  useTranslate,
} from '../data-mediation';
import { useClustering } from '../../state/clustering';
import { CURRENT_SESSION_INDEX } from '../utils/constants';
import { useQuestionModuleResults } from '../data-mediation/results';

interface LoaderPropsT {
  question: Question;
}

const NUMBER_OF_RESPONSES = 10;

function clusterControlEnabled(
  questionResults: QuestionModuleResult | EmptyT,
  ai_features_enabled: boolean,
  hasResults: boolean,
  isLatestSession: boolean,
  testVotesShowing: boolean,
): boolean {
  if (testVotesShowing) return true;
  if (questionResults?.type !== 'open') return false;
  const hasEnoughResponsesForClustering =
    hasResults && questionResults?.results?.length > NUMBER_OF_RESPONSES;
  return (
    ai_features_enabled && hasEnoughResponsesForClustering && isLatestSession
  );
}

function ModuleRenderer({ question }: LoaderPropsT) {
  const { activeSession } = useSessions();
  const isLatestSession = activeSession === CURRENT_SESSION_INDEX;

  const setControlsContext = useSetControlContext();
  const translate = useTranslate();
  const quizHasResults = useHasQuizResults();
  const questionResults = useQuestionModuleResults();
  const hasResults = Boolean(questionResults?.respondents) || quizHasResults;
  const {
    actions: { setSlideState, next, nextStep } = {
      setSlideState: () => {},
      next: () => {},
      nextStep: () => {},
    },
    state: { step, slideStates } = { step: 0 },
  } = usePace();
  const { enabled, hasNoClusters, onActivate } = useClustering();
  const { testVotesShowing } = useTestVotesState();

  const showClusterControl = clusterControlEnabled(
    questionResults,
    enabled,
    hasResults,
    isLatestSession,
    testVotesShowing,
  );

  // Make sure we have the same references
  const refs = React.useRef({
    setControlsContext,
  });

  React.useEffect(() => {
    const loadControls = async ({ signal }: { signal: AbortSignal }) => {
      const dynamicControlsFunction = await importControlsDynamically(
        question.module_id,
      );

      if (signal.aborted) {
        return;
      }

      if (dynamicControlsFunction) {
        const slideState = slideStates?.[question.public_key];
        const data = dynamicControlsFunction({
          question,
          step,
          nextStep: () => nextStep('Controls'),
          next: () => next('Controls'),
          translate,
          hasResults,
          showClusterControl,
          hasNoClusters,
          onActivateClustering: onActivate,
          slideState: slideState || {},
          setSlideState: (newState: SlideState) =>
            setSlideState(question.public_key, newState),
        });

        refs.current.setControlsContext(data);
      }
    };
    const ac = new AbortController();
    loadControls({ signal: ac.signal });
    return () => ac.abort();
  }, [
    question,
    hasResults,
    step,
    slideStates,
    showClusterControl,
    hasNoClusters,
    setSlideState,
    nextStep,
    translate,
    next,
    onActivate,
  ]);

  return null;
}

interface ControlsPropsT extends LoaderPropsT {
  debug?: boolean;
}

export const Controls = ({ debug, ...props }: ControlsPropsT) => (
  <HotkeyProvider keyStrokes={KEYSTROKES} DEBUG={debug ? HOTKEY_DEBUG : {}}>
    <ModuleRenderer {...props} />
  </HotkeyProvider>
);
