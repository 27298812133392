import * as React from 'react';
import { createComponent, type IRenderer } from '@mentimeter/ragnar-react';
import { useConfig } from '../data-mediation';

/*
 * Implementation of two components with (almost) the same API
 * The default export decides which one we use, but we could mix them if needed by named import.
 *
 * The CSS stagger is hypothesised to be more performant.
 */
type StaggerType = 'fade' | 'brand';

interface StaggerT {
  index: number;
  type?: StaggerType;
}

const variants: Record<
  StaggerType,
  Parameters<IRenderer['renderKeyframe']>[0]
> = {
  fade: () => ({
    // IE11 jumping animation fix
    '0.01%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  }),
  brand: () => ({
    // IE11 jumping animation fix
    '0.01%': {
      transform: `translateY(50px)`,
      opacity: 0,
    },
    '100%': {
      transform: `translateY(0)`,
      opacity: 1,
    },
  }),
};

interface StaggerCSST extends StaggerT {
  children: React.ReactNode;
  duration?: number | undefined;
  delay?: number | undefined;
  width?: string;
  flex?: string;
  maxWidth?: string;
  height?: string;
  style?: React.CSSProperties;
}

const createCssStagger = () => {
  const rule = (
    {
      type = 'brand',
      index = 0,
      duration = 400,
      delay = 250,
      flex = 'inherit',
      width = '100%',
      height = '100%',
      maxWidth = '100%',
    }: StaggerCSST,
    // @ts-expect-error-auto TS(7006) FIXME: Parameter 'renderer' implicitly has an 'any' type.
    renderer,
  ) => {
    const animation = renderer.renderKeyframe(variants[type]);
    return {
      animationName: animation,
      animationDuration: `${duration}ms`,
      animationTimingFunction: 'ease',
      animationFillMode: 'forwards',
      animationDelay: `${index * delay}ms`,
      display: 'inherit',
      flex,
      flexDirection: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      textAlign: 'inherit',
      font: 'inherit',
      width,
      height,
      maxWidth,
      minHeight: 'inherit',
      minWidth: 'inherit',
    };
  };
  return createComponent(rule, 'div');
};

const StaggerC: React.ComponentType<StaggerCSST> = createCssStagger();
// it's a component

export const Stagger = ({ duration, delay, ...rest }: StaggerCSST) => {
  const { enableAnimations } = useConfig();
  return (
    <StaggerC
      style={{ opacity: 0 }}
      {...rest} // Duration be 0ms due to animation not running (safari issue)
      duration={enableAnimations ? duration : 1}
      delay={enableAnimations ? delay : 0}
    />
  );
};
