import { createStaticUrl } from '../utils';
import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT = {
  id: 'waves',
  title: 'Waves',
  background: Background,
  preview: {
    idle: createStaticUrl('static/themes/waves/waves_theme_idle.jpg'),
    hover: createStaticUrl('static/themes/waves/waves_theme_hover.gif'),
  },
};

export default Export;
