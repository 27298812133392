import * as React from 'react';
import type {
  EmptyT,
  QuestionModuleResult,
} from '@mentimeter/question-modules-types';
import { useQuestionModulesPreviewResults } from '@mentimeter/question-modules-preview-results';
import { useQuestion } from '../data-mediation/question';
import { useQuestionResults } from '../data-mediation/question-results';
import { defaultState, SfinxResultsProvider } from '../data-mediation/results';
import { getIsQuizSlide } from '../utils/isQuizSlide';
import { useTestVotesState } from './presentation-actions/testVotes';

const EMPTY_RESULTS: EmptyT = {
  respondents: 0,
  type: 'empty',
  results: [],
  isPlaceholderResults: true,
};

const Results = ({ children }: { children: React.ReactNode }) => {
  const { type, choices, range, correct_area, hide_results, module_id } =
    useQuestion();

  const { testVotesShowing } = useTestVotesState();
  const isQuizSlide = getIsQuizSlide(type);
  const previewResultsEnabled = !isQuizSlide && testVotesShowing;

  const currentResult = useQuestionResults();
  const resultsAreMismatched = currentResult?.type !== type;

  const results = (currentResult ?? defaultState) as QuestionModuleResult;

  const previewResults =
    useQuestionModulesPreviewResults({
      moduleId: module_id,
      previewResultsEnabled,
      choices,
      range,
      correct_area,
      segments: results.segments,
    }) ?? EMPTY_RESULTS;

  const questionResults = React.useMemo(() => {
    if (previewResultsEnabled && !hide_results) {
      return previewResults;
    }

    if (resultsAreMismatched) {
      // Results can be mismatched for a short period of time when the slide type is changed
      return EMPTY_RESULTS;
    }

    if (type === 'wordcloud') {
      return previewResultsEnabled ? previewResults : results;
    }

    if (hide_results) {
      return EMPTY_RESULTS;
    }

    return results;
  }, [
    hide_results,
    results,
    previewResultsEnabled,
    previewResults,
    type,
    resultsAreMismatched,
  ]);

  return (
    <SfinxResultsProvider value={questionResults}>
      {children}
    </SfinxResultsProvider>
  );
};

export default Results;
