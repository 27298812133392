/* eslint-disable menti-react/filename-convention--jsx */
import { nature } from '@mentimeter/sfinx-themes';
import { useOptionalPace } from '../../data-mediation';

const C = nature.background;

export const Background = () => {
  const progress = useOptionalPace()?.selectors?.progress() ?? 0;
  return <C progress={progress} />;
};
