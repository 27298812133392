import type {
  PreviewResultsInputs,
  ScalesT,
} from '@mentimeter/question-modules-types';

const INITIAL_RESULTS: ScalesT = {
  respondents: 0,
  type: 'scales',
  results: [],
  isPlaceholderResults: false,
  timestamp: 0,
  segments: [],
};

export const getPreviewResultsIteration = ({
  previous = INITIAL_RESULTS,
  iteration,
  extras: { choices, range },
}: PreviewResultsInputs<ScalesT>): ScalesT => {
  const output = { ...previous };

  if (range) {
    if (iteration === 0 || previous.results.length === 0) {
      output.respondents = 0;
      output.results = choices.map(({ id, label }) => {
        const score: Record<number, number> = {};
        for (let j = range.min; j <= range.max; j++) {
          score[j] = 0;
        }
        return { id, label, score };
      });
    } else {
      const respondentsToAdd = Math.floor(Math.random() * 3) + 1;
      output.respondents += respondentsToAdd;
      output.results = previous.results.map(
        ({ id, label, score: prevScore }) => {
          const score = { ...prevScore }; // Prevent mutation
          const randomKey =
            Math.floor(Math.random() * (range.max - range.min + 1)) + range.min;
          for (let j = range.min; j <= range.max; j++) {
            if (j === randomKey) {
              // @ts-expect-error ts-migrate 5.5
              score[j] += respondentsToAdd;
            }
          }
          return { id, label, score };
        },
      );
    }
  }
  return output;
};
