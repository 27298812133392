/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import {
  CatReaction,
  ChevronDownIcon,
  ChevronUpIcon,
  HeartReaction,
  QuestionReaction,
  ThumbsDownReaction,
  ThumbsUpReaction,
} from '@mentimeter/ragnar-visuals';
import { FONT_SIZE_BASE_INDEX } from '../../canvas/config';
import type { FlexibleTextT } from './primitives';
import { FlexibleText } from './primitives';

export const Text = (props: FlexibleTextT) => (
  <FlexibleText fontSize={FONT_SIZE_BASE_INDEX} fontFamily="body" {...props} />
);

export const NextIcon = ChevronDownIcon;
export const PreviousIcon = ChevronUpIcon;

export const ReactionMap = {
  heart1: HeartReaction,
  cat: CatReaction,
  question: QuestionReaction,
  thumbsdown: ThumbsDownReaction,
  thumbsup: ThumbsUpReaction,
};

export { StringParser } from './StringParser';
