/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { colorjoy } from '@mentimeter/sfinx-themes';
import { useOptionalPace } from '../../data-mediation';

const BackgroundC = colorjoy.background;

const C = React.memo<React.ComponentProps<typeof BackgroundC>>((props) => (
  <BackgroundC {...props} />
));

export const Background = () => {
  const slideIndex = useOptionalPace()?.state?.slideIndex ?? 0;
  return <C index={slideIndex} />;
};
