import React from 'react';
import { useInterval } from '@mentimeter/react-hooks';

export const usePreviewResultsIterator = <T>(
  previewResultsEnabled: boolean,
  getNextResults: (iteration: number, previous: T | undefined) => T | undefined,
  timeToNextIteration: (iteration: number) => number = () => 2000,
) => {
  const [iteratorState, setIteratorState] = React.useState<{
    iteration: number;
    results: T | undefined;
  }>({
    iteration: 0,
    results: undefined,
  });

  const setNextTestResults = React.useCallback(
    () =>
      setIteratorState((state) => ({
        iteration: state.iteration + 1,
        results: getNextResults(state.iteration + 1, state.results),
      })),
    [getNextResults],
  );

  React.useEffect(() => {
    setIteratorState({
      iteration: 0,
      results: getNextResults(0, undefined),
    });
  }, [previewResultsEnabled, setIteratorState, getNextResults]);

  React.useEffect(() => {
    if (previewResultsEnabled) {
      setNextTestResults();
    }
  }, [setNextTestResults, previewResultsEnabled]);

  useInterval(
    setNextTestResults,
    previewResultsEnabled ? timeToNextIteration(iteratorState.iteration) : null,
  );

  return previewResultsEnabled ? iteratorState.results : undefined;
};
