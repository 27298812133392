/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import type {
  ControlValueT,
  ControlContextT,
} from '@mentimeter/question-modules-types';

export const defaultContext: Omit<ControlValueT, 'state'> = {
  controls: [],
  extraControls: [],
  contextualToolbarItems: [],
  navigationDisabled: false,
};

export const ControlContext = React.createContext<ControlContextT>([
  defaultContext,
  () => {},
]);
ControlContext.displayName = 'QuestionModulesControlContext';

export const ControlContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const controlContextValue = React.useState<ControlValueT>(defaultContext);
  return (
    <ControlContext.Provider value={controlContextValue}>
      {children}
    </ControlContext.Provider>
  );
};

export const useGetControlContext = () => React.useContext(ControlContext)[0];

export const useSetControlContext = () => {
  const [, setState] = React.useContext(ControlContext);
  // Reset on unmount
  React.useEffect(() => () => setState(defaultContext), [setState]);
  return setState;
};
