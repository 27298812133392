import { createStaticUrl } from '../utils';
import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT = {
  id: 'waves-blue',
  title: 'Blue Wave',
  background: Background,
  preview: {
    idle: createStaticUrl(
      'static/themes/waves-blue/waves-blue_theme_preview.jpg',
    ),
    hover: createStaticUrl(
      'static/themes/waves-blue/waves-blue_theme_hover.gif',
    ),
  },
};

export default Export;
