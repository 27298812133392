import * as React from 'react';

export interface TestVotesContext {
  testVotesShowing: boolean;
  setTestVotesShowing?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  trackSetTestVotesShowing?: (params: {
    placement: 'Presentation view menu' | 'Hotkey T';
  }) => void | undefined;
}

export const DEFAULT_STATE: TestVotesContext = {
  testVotesShowing: false,
  setTestVotesShowing: () => {},
  trackSetTestVotesShowing: () => {},
};

export const context = React.createContext<TestVotesContext>(DEFAULT_STATE);
context.displayName = 'TestVotesContext';
export const TestVotesProvider = context.Provider;

export const useTestVotesState = () => React.useContext(context);
