import type { ModuleId, ValidModuleId } from '@mentimeter/http-clients';
import type {
  ControlsFunctionInputT,
  ControlsFunctionReturnT,
} from '@mentimeter/question-modules-types';
import { getValidModuleIdFromString } from './get-valid-module-id';

const MODULE_IMPORT_MAP: Record<
  ValidModuleId,
  () => Promise<{
    Controls: (arg: ControlsFunctionInputT) => ControlsFunctionReturnT;
  }>
> = {
  '100-points': () => import('@mentimeter/question-module-100-points/controls'),
  big: () => import('@mentimeter/question-module-big/controls'),
  bullets: () => import('@mentimeter/question-module-bullets/controls'),
  choices: () => import('@mentimeter/question-module-choices/controls'),
  deprecated: () => import('@mentimeter/question-module-deprecated/controls'),
  document: () => import('@mentimeter/question-module-document/controls'),
  'free-text': () => import('@mentimeter/question-module-free-text/controls'),
  'google-slides': () =>
    import('@mentimeter/question-module-google-slides/controls'),
  heading: () => import('@mentimeter/question-module-heading/controls'),
  image: () => import('@mentimeter/question-module-image/controls'),
  instructions: () =>
    import('@mentimeter/question-module-instructions/controls'),
  metadata: () => import('@mentimeter/question-module-metadata/controls'),
  miro: () => import('@mentimeter/question-module-miro/controls'),
  number: () => import('@mentimeter/question-module-number/controls'),
  'numerical-question': () =>
    import('@mentimeter/question-module-numerical-question/controls'),
  open: () => import('@mentimeter/question-module-open/controls'),
  paragraph: () => import('@mentimeter/question-module-paragraph/controls'),
  'pin-on-image': () =>
    import('@mentimeter/question-module-pin-on-image/controls'),
  powerpoint: () => import('@mentimeter/question-module-powerpoint/controls'),
  qfa: () => import('@mentimeter/question-module-qfa/controls'),
  'quiz-choices': () =>
    import('@mentimeter/question-module-quiz-choices/controls'),
  'quiz-leaderboard': () =>
    import('@mentimeter/question-module-quiz-leaderboard/controls'),
  'quiz-open': () => import('@mentimeter/question-module-quiz-open/controls'),
  quote: () => import('@mentimeter/question-module-quote/controls'),
  ranking: () => import('@mentimeter/question-module-ranking/controls'),
  rating: () => import('@mentimeter/question-module-rating/controls'),
  scales: () => import('@mentimeter/question-module-scales/controls'),
  video: () => import('@mentimeter/question-module-video/controls'),
  winner: () => import('@mentimeter/question-module-winner/controls'),
  wordcloud: () => import('@mentimeter/question-module-wordcloud/controls'),
};

export async function importControlsDynamically(moduleId: ModuleId) {
  const validModuleId = getValidModuleIdFromString(moduleId);
  const promisedPath = MODULE_IMPORT_MAP[validModuleId];

  // Not using Next.js dynamic import as "Controls" is not a React component
  const controls = await promisedPath().catch((error) => {
    throw new Error(
      `Question modules: Failed to load Presentation dynamically for moduleId: '${moduleId}'. Error message: ${error}`,
    );
  });
  return controls.Controls;
}
