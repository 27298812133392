import * as React from 'react';
import type { IStyle } from 'fela';
import type { ContinuousItemT } from '../components/Continuous';
import Continuous, { create } from '../components/Continuous';
import { FILL, createStaticUrl } from '../utils';

const wave1 = createStaticUrl('static/themes/waves-blue/wave-1.svg');

const wave2 = createStaticUrl('static/themes/waves-blue/wave-2.svg');

const wave1Transform: Record<string, IStyle> = {
  '0%': { transform: `translateX(0%)` },
  '100%': { transform: `translateX(-50%)` },
};

const wave2Transform: Record<string, IStyle> = {
  '0%': { transform: `translateX(0%)` },
  '100%': { transform: `translateX(-50%)` },
};

const map: Array<ContinuousItemT> = [
  {
    id: 'wave-2',
    component: create(
      {
        ...FILL,
        backgroundImage: `url(${wave2})`,
        backgroundSize: 'cover',
        width: '310%',
        height: '100%',
        top: '40%',
        opacity: 1.0,
      },
      wave2Transform,
    ),
    props: {
      delay: 0,
      duration: 20000,
      easing: 'cubic-bezier(0.5, 0.4, 0.4, 0.6)',
    },
  },
  {
    id: 'wave-1',
    component: create(
      {
        ...FILL,
        backgroundImage: `url(${wave1})`,
        backgroundSize: 'cover',
        width: '310%',
        height: '100%',
        top: '60%',
        opacity: 1.0,
      },
      wave1Transform,
    ),
    props: {
      delay: 0,
      duration: 20000,
      easing: 'cubic-bezier(0.4, 0.5, 0.6, 0.4)',
    },
  },
];

const WavesBlue = () => {
  return <Continuous components={map} />;
};

export default WavesBlue;
