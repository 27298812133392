import type { CSSProperties } from 'react';
import React from 'react';

export interface ParallaxT {
  imageUrls: Array<string>;
  progress: number;
}

const FILL: CSSProperties = {
  zIndex: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const Parallax = ({ imageUrls, progress }: ParallaxT) => {
  const numberOfLayers = imageUrls.length;
  return (
    <div style={FILL}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {imageUrls.map((url, i) => {
          const offset = 15;
          const f = i / (numberOfLayers - 1);
          const style = {
            ...FILL,
            bottom: 0,
            left: 0,
            backgroundImage: `url(${url})`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundAttachement: 'fixed',
            backgroundSize: `cover`,
            transform: `scale(1.${offset}) translateX(-${
              progress * offset * f
            }%)`,
            transformOrigin: 'bottom left',
            zIndex: i + 1,
            transition: `transform 1s ease-in-out`,
          } as React.CSSProperties;

          return <div key={`parallax-layer-${url}`} style={style} />;
        })}
      </div>
    </div>
  );
};

export default Parallax;
