import * as React from 'react';
import type { SfinxConfigData } from './types';

const context = React.createContext<SfinxConfigData | undefined>(undefined);

/**
 * Provider that contains static data defined by which view sfinx is in
 */
export const ConfigProvider = context.Provider;
context.displayName = 'ConfigContext';

const defaultState: Partial<SfinxConfigData> = {
  renderSlideControls: true,
  enableEmbedSlides: true,
};

export const useConfig = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('SfinxError: no config data found');
  }
  return { ...defaultState, ...data };
};
