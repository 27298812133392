import React from 'react';

export interface ProfanitiesState {
  profanities: string[];
}

const context = React.createContext<ProfanitiesState>({ profanities: [] });

context.displayName = 'ProfanitiesContext';

export const ProfanitiesProvider = context.Provider;
export const useProfanities = () => React.useContext(context).profanities;
