import { createStaticUrl } from '../utils';
import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT = {
  id: 'waves-purple',
  title: 'Purple Wave',
  background: Background,
  preview: {
    idle: createStaticUrl(
      'static/themes/waves-purple/waves-purple_theme_preview.jpg',
    ),
    hover: createStaticUrl(
      'static/themes/waves-purple/waves-purple_theme_hover.gif',
    ),
  },
};

export default Export;
