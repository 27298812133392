import type { Theme } from '@mentimeter/http-clients';
import type { SfinxThemeState } from './types';

export const themeOverride = (raw: Theme): SfinxThemeState | undefined => {
  if (raw === undefined) return undefined;

  // @ts-expect-error-auto TS(2375) FIXME: Type '{ font: ThemeFont; is_public?: true; bar_col... Remove this comment to see the full error message
  return {
    ...(raw.logo_url && { logo: raw.logo_url }),
    ...(typeof raw.backdrop_alpha === 'number' && {
      backdrop_alpha: raw.backdrop_alpha,
    }),
    ...(raw.background_color && { background_color: raw.background_color }),
    ...(raw.text_color && { text_color: raw.text_color }),
    ...(raw.line_color && { line_color: raw.line_color }),
    ...(raw.bar_color && { bar_color: raw.bar_color }),
    ...(raw.is_public && { is_public: raw.is_public }),
    ...(raw.font && { font: raw.font }),
  };
};
