import * as React from 'react';
import type { SfinxThemeState } from './types';

const context = React.createContext<SfinxThemeState | undefined>(undefined);

export const ThemeContextProvider = context.Provider;

export const useTheme = () => {
  const data = React.useContext(context);
  if (!data) {
    throw new Error('useTheme must be used within a ThemeContextProvider');
  }
  return data;
};
