import React from 'react';
import type { PresentationSDKT } from '@mentimeter/question-modules-types';

export const PresentationContext = React.createContext<PresentationSDKT | null>(
  null,
);
PresentationContext.displayName = 'QuestionModulesPresentationContext';

export function usePresentationContext() {
  const context = React.useContext(PresentationContext);
  if (!context) {
    throw new Error(
      'Trying to use presentation context before it is set or without adding a provider.',
    );
  }

  return context;
}
