import * as React from 'react';
import { createStaticUrl } from '../utils';
import Parallax from '../components/Parallax';

const layer1 = createStaticUrl(`static/themes/nature/background.jpg`);
const layer2 = createStaticUrl(`static/themes/nature/layer-2.png`);
const layer3 = createStaticUrl(`static/themes/nature/layer-3.png`);
const layer4 = createStaticUrl(`static/themes/nature/layer-4.png`);

const LAYERS = [layer1, layer2, layer3, layer4];

const Nature = ({ progress }: { progress: number }) => {
  return <Parallax imageUrls={LAYERS} progress={progress} />;
};

export default Nature;
