import { createStaticUrl } from '../utils';
import type { ThemePackageT } from '../types';
import Background from './Background';

const Export: ThemePackageT<React.ComponentProps<typeof Background>> = {
  id: 'explore',
  title: 'Explore',
  background: Background,
  preview: {
    idle: createStaticUrl('static/themes/explore/explore_theme_idle.jpg'),
    hover: createStaticUrl('static/themes/explore/explore_theme_hover.gif'),
  },
};

export default Export;
