import * as React from 'react';

export type SfinxVoteCodeState = string | undefined;

const context = React.createContext<SfinxVoteCodeState>(undefined);

export const VoteCodeProvider = context.Provider;
context.displayName = 'VoteCodeContext';

export const useVoteCode = () => React.useContext(context);
