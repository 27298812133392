import { useContext, useEffect } from 'react';
import { takeScreenshot } from './takeScreenshot';
import { ScreenshotDataContext } from './ScreenshotDataContext';

const LABEL_CALCULATION_WAIT = 3000;

export const ScreenshotTrigger = () => {
  const { isAllDataForScreenshotLoaded } = useContext(ScreenshotDataContext);

  useEffect(() => {
    if (isAllDataForScreenshotLoaded) {
      setTimeout(() => takeScreenshot(), LABEL_CALCULATION_WAIT);
    }
  }, [isAllDataForScreenshotLoaded]);

  return null;
};
