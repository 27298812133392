import type {
  PreviewResultsInputs,
  WordCloudT,
} from '@mentimeter/question-modules-types';

export const WORD_CLOUD_TEXTS = [
  { text: 'creative', count: 8 },
  { text: 'bold', count: 3 },
  { text: 'fast', count: 8 },
  { text: 'Inspiration', count: 1 },
  { text: 'transpiration', count: 2 },
  { text: 'leader', count: 5 },
  { text: 'focus', count: 4 },
  { text: 'leadership', count: 2 },
  { text: 'quick', count: 3 },
  { text: 'inspirational', count: 2 },
  { text: 'inspire ', count: 3 },
  { text: 'top', count: 2 },
  { text: 'quality', count: 5 },
  { text: 'sharing', count: 2 },
  { text: 'leading', count: 2 },
  { text: 'integration', count: 2 },
  { text: 'innovative', count: 1 },
  { text: 'performance', count: 1 },
  { text: 'innovation', count: 1 },
  { text: 'transformers', count: 1 },
  { text: 'change', count: 1 },
];

const INITIAL_RESULTS: WordCloudT = {
  respondents: 0,
  type: 'wordcloud',
  results: [],
  isPlaceholderResults: false,
  timestamp: 0,
  segments: [],
};

export const getPreviewResultsIteration = ({
  previous = INITIAL_RESULTS,
  iteration,
}: PreviewResultsInputs<WordCloudT>): WordCloudT => {
  const output = {
    ...previous,
  };

  if (iteration === 0) {
    return INITIAL_RESULTS;
  }

  const testVote = WORD_CLOUD_TEXTS[iteration - 1];
  if (testVote) {
    output.respondents += Math.floor(Math.random() * 3 + 1);
    output.results = [...previous.results, testVote];
  }
  return output;
};
