/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { wavespurple } from '@mentimeter/sfinx-themes';

const BackgroundC = wavespurple.background;

const C = React.memo<Record<string, unknown>>(() => <BackgroundC />);

export const Background = () => {
  return <C />;
};
