/* eslint-disable menti-react/filename-convention--jsx */
import { space } from '@mentimeter/sfinx-themes';
import { useOptionalPace } from '../../data-mediation';

const C = space.background;

export const Background = () => {
  const slideIndex = useOptionalPace()?.state?.slideIndex ?? 0;
  const totalSlides = useOptionalPace()?.state?.totalSlides ?? 0;
  const progress = useOptionalPace()?.selectors?.progress() ?? 0;
  return (
    <C progress={progress} currentIndex={slideIndex} total={totalSlides} />
  );
};
