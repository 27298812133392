import type { CSSProperties } from 'react';
import * as React from 'react';
import type { IStyle } from 'fela';
import type { ContinuousItemT } from '../components/Continuous';
import Continuous, { create } from '../components/Continuous';
import { FILL } from '../utils';

const keyframes: Record<string, IStyle> = {
  '0%': { backgroundPosition: '84% 0%' },
  '50%': { backgroundPosition: '17% 100%' },
  '100%': { backgroundPosition: '84% 0%' },
};

const createCss = (colors: Array<string>): CSSProperties => {
  return {
    background: `linear-gradient(222deg, ${colors.join(', ')})`,
    backgroundImage: `-ms-linear-gradient(222deg, ${colors.join(', ')})`,
    backgroundSize: '800% 800% !important',
    ...FILL,
  };
};

const MAP: Array<ContinuousItemT> = [
  {
    id: 'variant-1',
    component: create(
      createCss(['#2cd5aa', '#2c82d5', '#a72cd5', '#ea4b9e', '#eabd4b']),
      keyframes,
    ),
  },
];

const Gradient = () => {
  return <Continuous components={MAP} />;
};

export default Gradient;
