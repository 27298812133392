import React, { useEffect } from 'react';

export interface Props {
  useOffset?: boolean;
  callback: (arg0: number, arg1: number, arg2: number, arg3: number) => void;
}

export const Resizer = ({ callback, useOffset }: Props) => {
  const container = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = container.current;

    if (!element || !window.ResizeObserver) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const element = entry.target as HTMLDivElement;
        const dim = element.getBoundingClientRect();
        callback(
          useOffset ? element.offsetTop : dim.top,
          useOffset ? element.offsetLeft : dim.left,
          useOffset ? element.offsetWidth : dim.width,
          useOffset ? element.offsetHeight : dim.height,
        );
      });
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [callback, useOffset]);

  return (
    <div
      ref={container}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: -5,
      }}
    />
  );
};
