/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import {
  type Question,
  type QuestionSubType,
  type QuestionType,
  type SegmentT,
} from '@mentimeter/http-clients';
import type { ChoicesResultT } from '@mentimeter/visualizations';
import { trackUser } from '@api/tracking/client';
import { useDispatch } from '../dispatcher';
import { useQuestion } from '../../data-mediation/question';
import { useQuestionResults } from '../../data-mediation/question-results';
import type { SegmentableQuestion, SfinxSegmentationState } from './types';
import { addUnknownSegmentIfNeeded } from './unknown-segment';

const defaultState = {
  segmentedBy: undefined,
  segmentableQuestions: [],
};

const context = React.createContext<SfinxSegmentationState>(defaultState);
context.displayName = 'SegmentationContext';

const slideIsSegmentable = (type: QuestionType, subType: QuestionSubType) => {
  return (
    (type === 'choices' || type === 'choices_images') && // legacy
    subType !== 'pie' &&
    subType !== 'donut'
  );
};

export const SegmentationProvider = (props: {
  children: React.ReactNode;
  value: SfinxSegmentationState;
}) => <context.Provider {...props} />;

export const useSegmentation = (): {
  segments: SegmentT[];
  setSegmentationBy: (id?: SegmentableQuestion['id']) => void;
  segmentableQuestions: SegmentableQuestion[];
  isSegmentable: boolean;
  segmentedBy: Question | undefined;
} => {
  const dispatch = useDispatch();
  const question = useQuestion();
  const results = useQuestionResults();
  const { segmentedBy, segmentableQuestions } = React.useContext(context);

  const segments = React.useMemo(() => {
    if (results?.type !== 'choices' || !segmentedBy) {
      return [];
    }

    return addUnknownSegmentIfNeeded({
      results: results.results as ChoicesResultT[],
      segments: results.segments!,
      segmentedBy,
    });
  }, [results?.type, results?.results, results?.segments, segmentedBy]);

  const isSegmentable = slideIsSegmentable(question.type, question.sub_type);

  return {
    segments,
    setSegmentationBy: (segmentBy = '') => {
      dispatch({
        type: 'SET_SEGMENTATION_BY',
        payload: { questionId: question.id, segmentBy },
      });
      void trackUser({
        event: 'Updated segmentation',
        properties: {
          context: 'Presentation view',
          'question type': question.module_id,
        },
        options: {
          set_last_used: true,
        },
      });
    },
    segmentableQuestions,
    isSegmentable,
    segmentedBy,
  };
};
